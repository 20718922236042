export function ddmmyyyyFormat(date) {
  var today = new Date(date);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return dd + "/" + mm + "/" + yyyy;
}

// Set firstDate to beginning (0:0:0:0) and lastDate to end (23:59:59:999)
export function normalizeDateRange(dr) {
  const firstDate = new Date(dr[0]);
  const lastDate = new Date(dr[1]);
  firstDate.setHours(0);
  firstDate.setMinutes(0);
  firstDate.setSeconds(0);
  firstDate.setMilliseconds(0);
  lastDate.setHours(23);
  lastDate.setMinutes(59);
  lastDate.setSeconds(59);
  lastDate.setMilliseconds(999);
  return [firstDate, lastDate];
}

export function isToday(someDate) {
  const today = new Date();
  return someDate.getDate() == today.getDate() && someDate.getMonth() == today.getMonth() && someDate.getFullYear() == today.getFullYear();
}

export function sameDateRange(newRange, oldRange) {
  const firstOldDate = new Date(oldRange[0]);
  const lastOldDate = new Date(oldRange[1]);

  const firstNewDate = new Date(newRange[0]);
  const lastNewDate = new Date(newRange[1]);

  return firstNewDate.getTime() === firstOldDate.getTime() && lastOldDate.getTime() === lastNewDate.getTime();
}
