/* import { useI18n } from "vue-i18n"; */
import i18n from "../i18n";

export function translateDataType(dataType) {
  /* const { t } = useI18n(); */
  const normalizedDataType = (dataType || "").toLowerCase();
  let translated;
  switch (normalizedDataType) {
    case "distance":
      translated = i18n.t("dataTypes.distance").toLowerCase();
      break;
    // case "distance2":
    //   translated = i18n.t("dataTypes.distance2").toLowerCase();
    //   break;
    case "pulse1":
      translated = i18n.t("dataTypes.pulses").toLowerCase() + " (CH.1)";
      break;
    case "pulse2":
      translated = i18n.t("dataTypes.pulses").toLowerCase() + " (CH.2)";
      break;
    case "temperature":
      translated = i18n.t("dataTypes.temperature").toLowerCase();
      break;
    case "temperature2":
      translated = i18n.t("dataTypes.temperature2").toLowerCase();
      break;
    case "pressure":
      translated = i18n.t("dataTypes.pressure").toLowerCase();
      break;
    case "pressure2":
      translated = i18n.t("dataTypes.pressure2").toLowerCase();
      break;
    case "digital":
      translated = "contatto";
      break;
    case "digital2":
      translated = "contatto (2)";
      break;
    case "volume":
      translated = "volume";
      break;
    case "rain":
      translated = i18n.t("dataTypes.rain").toLowerCase();
      break;
    case "rain2":
      translated = i18n.t("dataTypes.rain2").toLowerCase();
      break;
    case "humidity":
      translated = i18n.t("dataTypes.humidity").toLowerCase();
      break;
    case "humidity2":
      translated = i18n.t("dataTypes.humidity2").toLowerCase();
      break;
    case "accmotion":
      translated = "accMotion";
      break;
    case "acceleration":
      translated = i18n.t("dataTypes.acceleration").toLowerCase();
      break;
    case "acceleration2":
      translated = i18n.t("dataTypes.acceleration2").toLowerCase();
      break;
    case "flowrate":
      translated = i18n.t("dataTypes.flowrate").toLowerCase();
      break;
    case "flowrate2":
      translated = i18n.t("dataTypes.flowrate2").toLowerCase();
      break;
    case "velocity":
      translated = i18n.t("dataTypes.velocity").toLowerCase();
      break;
    case "velocity2":
      translated = i18n.t("dataTypes.velocity2").toLowerCase();
    case "volume":
      translated = i18n.t("dataTypes.volume").toLowerCase();
      break;
    case "volume2":
      translated = i18n.t("dataTypes.volume2").toLowerCase();
      break;
    case "impulse":
      translated = i18n.t("dataTypes.pulses").toLowerCase();
      break;
    case "impulse2":
      translated = i18n.t("dataTypes.pulses2").toLowerCase();
      break;
    case "generic":
      translated = i18n.t("dataTypes.generic").toLowerCase();
      break;
    case "generic2":
      translated = i18n.t("dataTypes.generic2").toLowerCase();
      break;
    case "power":
      translated = i18n.t("dataTypes.power").toLowerCase();
      break;
    case "power2":
      translated = i18n.t("dataTypes.power2").toLowerCase();
      break;
    case "energy":
      translated = i18n.t("dataTypes.energy").toLowerCase();
      break;
    case "energy2":
      translated = i18n.t("dataTypes.energy2").toLowerCase();
      break;
    default:
      translated = dataType;
      break;
  }
  return translated;
}

export function translateDataTypes(dataTypes) {
  const translatedArray = dataTypes ? dataTypes.filter((dt) => dt != null || dt != undefined).map((dt) => translateDataType(dt)) : null;
  return translatedArray;
}
