<template>
  <div class="row mr-2">
    <div class="col-6 p-4">
      <div class="col-12 pl-0 flex">
        <label>TCP ModBus abilitato:</label>
        <base-switch type="info" on-text="ON" off-text="OFF" v-model="sensorObj.modBus.enabled"></base-switch>
      </div>
      <label>Sample Rate</label>
      <el-select class="select-info" v-model="sensorObj.modBus.sampleRate" placeholder="Sample Rate">
        <el-option v-for="val in [10, 30, 60]" class="select-info" :value="val" :label="val + ' [min]'" :key="val"> </el-option>
      </el-select>
      <ValidationProvider name="host" rules="required" v-slot="{ passed, failed }">
        <base-input type="text" v-model="sensorObj.modBus.host" label="Host" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"></base-input>
      </ValidationProvider>
      <ValidationProvider name="port" rules="integer" v-slot="{ passed, failed }">
        <base-input type="number" v-model="sensorObj.modBus.port" label="Port" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"></base-input>
      </ValidationProvider>
    </div>
    <div class="col-6 p-4">
      <div class="row mr-2">
        <base-button class="col-4 auto-m" size="sm" :wide="false" :block="true" :simple="false" type="info" @click="addAddress" round>Aggiungi indirizzo</base-button>
      </div>
      <div class="list">
        <div class="row mr-2" v-for="(add, index) in sensorObj.modBus.map" :key="index">
          <div class="col-4 centered">
            <label>Abilitato</label>
            <base-switch type="info" on-text="ON" off-text="OFF" v-model="add.enabled"></base-switch>
          </div>
          <div class="col-4">
            <ValidationProvider name="address" rules="integer" v-slot="{ passed, failed }">
              <base-input type="number" v-model="add.address" label="Address" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"></base-input>
            </ValidationProvider>
          </div>
          <div class="col-4">
            <label>Source</label>
            <el-select class="select-info" v-model="add.input" placeholder="Source">
              <el-option v-for="newSource in sensorData" class="select-info" :value="newSource" :label="translateDataType(newSource)" :key="newSource"> </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import { Select, Option } from "element-ui";
import { BaseSwitch } from "src/components/index";
import { translateDataType } from "src/helpers/translation";
import { sensorData } from "src/helpers/device";

export default {
  name: "ModBus",
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseSwitch,
  },
  props: {
    value: {
      type: Object,
    },
  },
  methods: {
    translateDataType: translateDataType,
    addAddress() {
      this.sensorObj.modBus.map.push({
        enabled: true,
        address: 200,
        input: "analog1",
      });
    },
  },
  computed: {
    sensorData: () => sensorData,
    sensorObj: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style scoped>
label {
  padding-right: 8px;
}
.warning {
  color: yellow;
}
.error {
  color: red;
}
.success {
  color: green;
}
.list {
  padding: 20px;
  max-height: 250px;
  overflow: scroll;
  border: gray 1px solid;
  border-radius: 10px;
  margin: 20px;
}
</style>
