<template>
  <div class="row">
    <div class="col-12">
      <AllSensors />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Option, Dialog } from "element-ui";
import AllSensors from "./Inventary/AllSensorsList.vue";

export default {
  components: {
    AllSensors,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
  },
  computed: {
    ...mapGetters({ getUserList: "admin/getUserList" }),
  },
  methods: {
    ...mapActions({ fetchUserList: "admin/fetchUserList", fetchUserInfo: "user/fetchUserInfo" }),
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
  },
  async mounted() {
    this.startLoading();
    await this.fetchUserInfo();
    await this.fetchUserList();
    this.stopLoading();
  },
  watch: {},
  beforeDestroy() {},
};
</script>
<style scoped>
.flat-card > .card-body {
  padding: 0;
}
.flat-card {
  height: calc(100% - 20px);
  margin-bottom: 20px;
}
.select-primary {
  width: 100%;
}
</style>
