<template>
  <qrcode-vue :value="text" :size="size" level="H" />
</template>
<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'qr-code',
  components: {
    QrcodeVue
  },
  props: {
    text: String
  },
  data() {
    return {
      size: 200,
    }
  },
};
</script>
