<template>
  <div class="row">
    <div class="col-12">
      <card card-body-classes="table-full-width">
        <ValidationObserver v-slot="{ invalid }">
          <form autocomplete="do-not-autofill">
            <card footer-classes="text-right">
              <div slot="header"><h4 class="card-title">Quick edit gruppo</h4></div>
              <div class="row">
                <!-- Nome -->
                <ValidationProvider class="col-md-12" name="name" :rules="{ required: true, regex: /^\S{3,16}$/ }" v-slot="{ passed, failed }">
                  <base-input required v-model="groupObj.name" type="name" label="Nome" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"> </base-input>
                </ValidationProvider>
                <div class="col-md-12 category form-category">* Required fields</div>
              </div>
              <template slot="footer">
                <div class="row">
                  <div class="col-6 flex">
                    <base-button size="sm" type="success" class="auto-m" @click="close">Chiudi</base-button>
                  </div>
                  <div class="col-6 flex">
                    <base-button size="sm" type="primary" class="auto-m" @click="saveGroup" :disabled="invalid">{{ existingGroup ? "Salva" : "Aggiungi" }}</base-button>
                  </div>
                </div>
              </template>
            </card>
          </form>
        </ValidationObserver>
      </card>
    </div>
  </div>
</template>
<script>
//import { Select, Option } from "element-ui";

export default {
  props: {
    existingGroup: Object,
  },
  //components: { [Option.name]: Option, [Select.name]: Select },
  data() {
    return {
      groupObj: {},
    };
  },
  methods: {
    submit() {
      console.log("Form has been submitted!");
    },
    saveGroup() {
      console.log("Salvo gruppo", this.groupObj);
      this.$emit("save", this.groupObj);
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    if (this.existingGroup) {
      this.groupObj = JSON.parse(JSON.stringify(this.existingGroup));
    }
  },
};
</script>
