<template>
  <div
    ref="draggableRef"
    v-if="sensorObj"
    :class="[isSensorSelected ? 'device_tooltip-blue' : '', isSensorOnline ? 'online-device' : '', isSensorAlarmed ? 'device_tooltip-red' : '', 'draggable device_tooltip noselect']"
    :style="getStylePosition"
    :draggable="draggable"
    @click="selectDevice"
    @mousedown="dragMouseDown"
    :aria-label="sensorObj.userConfig.name || sensorObj.device.substring(sensorObj.device.length - 4)"
    data-balloon-visible
    data-balloon-pos="up"
  ></div>
</template>
<script>
import "balloon-css";
export default {
  name: "DraggableDevice",
  props: {
    draggable: Boolean,
    sensorObj: Object,
    selectedDeviceID: String,
    editingDevice: String,
    containerHeight: Number,
    containerWidth: Number
  },
  data() {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    };
  },
  computed: {
    getStylePosition() {
      const avSensor = this.sensorObj.loc.find(loc => loc.type === "img");
      if (avSensor) {
        const coordinates = avSensor.coordinates;
        const left = coordinates[0];
        const top = coordinates[1];
        return { top: "calc(" + top + "% - " + 10 + "px)", left: "calc(" + left + "% - " + 10 + "px)" };
      } else {
        return { top: 0, left: 0 };
      }
    },
    isSensorSelected() {
      return this.selectedDeviceID === this.sensorObj._id;
    },
    isSensorOnline() {
      const lastTX = this.sensorObj.lastTX;
      //const MIN_30 = 30 * 60 * 1000;
      const MIN_62 = 62 * 60 * 1000;
      const timeDiffMS = new Date() - new Date(lastTX);
      return lastTX && timeDiffMS < MIN_62 || !this.sensorObj.staticType === "FTP-CAMERA";
    },
    isSensorAlarmed() {
      return this.sensorObj && this.sensorObj.userConfig.alerts.some(al => al.status === true);
    }
  },
  methods: {
    selectDevice() {
      this.$emit("select-device", this.sensorObj);
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
    elementDrag(event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      const newTop = this.$refs.draggableRef.offsetTop - this.positions.movementY;
      const newLeft = this.$refs.draggableRef.offsetLeft - this.positions.movementX;
      if (newTop > 0 && newLeft > 0 && newTop + 20 < this.containerHeight && newLeft + 20 < this.containerWidth) {
        this.$refs.draggableRef.style.top = newTop + "px";
        this.$refs.draggableRef.style.left = newLeft + "px";
        const percLeft = (parseInt(this.$refs.draggableRef.style.left) + 10) / this.containerWidth;
        const percTop = (parseInt(this.$refs.draggableRef.style.top) + 10) / this.containerHeight;
        if (!isNaN(percLeft) && !isNaN(percTop)) {
          this.$emit("update-sensor-position", [parseFloat((percLeft * 100).toFixed(4)), parseFloat((percTop * 100).toFixed(4))]);
          console.log([parseFloat((percLeft * 100).toFixed(4)), parseFloat((percTop * 100).toFixed(4))]);
        }
      } else {
        closeDragElement();
      }
    },
    dragMouseDown(event) {
      if (this.draggable) {
        event.preventDefault();
        // get the mouse cursor position at startup:
        this.positions.clientX = event.clientX;
        this.positions.clientY = event.clientY;
        document.onmousemove = this.elementDrag;
        document.touchmove = this.elementDrag;
        document.onmouseup = this.closeDragElement;
        document.touchend = this.closeDragElement;
      }
    }
  }
};
</script>
<style scoped>
[aria-label][data-balloon-pos]:after {
  padding: 0.1em 0.3em !important;
}
.draggable {
  position: absolute;
  top: 100px;
  left: 50px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin: 0;
  padding: 0;
  background-color: red;
}

.draggable:active {
  background-color: rgba(168, 218, 220, 1);
}
.draggable:hover {
  cursor: pointer;
  /* border-width: 20px; */
}

/* Sensori */

.online-device {
  background-color: green;
}

/* Add this to your CSS */
.device_tooltip {
  --balloon-font-size: 18px;
  --balloon-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.device_tooltip-red {
  --balloon-color: rgb(255, 0, 0);
}
.device_tooltip-blue {
  --balloon-color: rgb(72, 129, 247);
}
.device_tooltip-slide {
  --balloon-move: 30px;
}

/* Prevents inconsistent highlighting of element while being dragged
   Copied from https://stackoverflow.com/questions/826782 */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
</style>
