<template>
  <ValidationObserver>
    <div id="main" class="row pt-3" v-if="tempStation">
      <!-- Warning -->
      <div class="col-12" v-if="hasDuplicatedCustomIDs">
        <p class="error strong">Errore: customID duplicati!</p>
      </div>
      <!-- Station ID -->
      <div class="col-12 mt-2">
        <ValidationProvider name="id" rules="required|min:3" v-slot="{ passed, failed, errors }">
          <base-input
            @input="unsavedChanges = true"
            required
            v-model="tempStation.stationID"
            label="Station ID"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
          >
          </base-input>
        </ValidationProvider>
      </div>
      <div class="col-12 mt-3">
        <card class="nested-card">
          <div slot="header"><h4 class="card-title">Sorgenti di dati:</h4></div>
          <collapse :multiple-active="false" class="col-12">
            <!-- Itero sulle sorgenti -->
            <collapse-item
              v-for="(source, index) in tempStation.sources"
              :key="index"
              :title="
                'Sorgente ' + (index + 1) + ' (' + translateDataType(source.dataType) + ' | ' + source.customID + ' | ' + (getSensorObjByID(source.sensor) || {}).device + ')'
              "
            >
              <div class="row pt-3">
                <Source v-model="tempStation.sources[index]" :key="index" :actualSensors="actualSensors" />
                <!-- Rimuovi sorgente -->
                <div class="col-12 mt-4">
                  <base-button class="auto-m" size="sm" :wide="false" :block="true" :simple="true" type="warning" @click="removeSource(index)">Elimina sorgente</base-button>
                </div>
              </div>
            </collapse-item>
          </collapse>
          <!-- Aggiungi sorgente -->
          <div class="col-12 mt-4">
            <base-button class="auto-m" size="sm" :wide="false" :block="true" :simple="false" type="success" @click="addSource()" :disabled="!checkSourcesValidation"
              >Aggiungi sorgente</base-button
            >
          </div>
        </card>
      </div>
      <div class="col-12">
        <base-button class="auto-m mt-4" size="sm" :wide="false" :block="true" :simple="true" type="warning" @click="removeStation">Elimina output selezionato</base-button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { Select, Option } from "element-ui";
import { Collapse, CollapseItem } from "src/components/index";
import Source from "./Source.vue";
import { translateDataType } from "src/helpers/translation";
import { getAvailableDataTypesV2, getDefaultMesUnitsByDataType, getMesUnitsByDataType } from "src/helpers/device";

export default {
  components: { Collapse, CollapseItem, [Option.name]: Option, [Select.name]: Select, Source },
  props: {
    value: Object, // v-model
    actualSensors: Array, // TUTTI i sensori presenti
    stationIndex: Number, // Indice dell'output iniettato (mi serve per distinguerlo dagli altri)
  },
  data() {
    return {
      tempStation: null,
    };
  },
  methods: {
    translateDataType: translateDataType,
    getDefaultMesUnitsByDataType: getDefaultMesUnitsByDataType,
    getMesUnitsByDataType: getMesUnitsByDataType,
    removeStation() {
      this.$emit("remove-me");
    },
    getSensorObjByID(sensorID) {
      return (this.actualSensors || []).find((sn) => sn._id === sensorID);
    },
    getAvailableDataTypes(sensorID) {
      if (sensorID) {
        return getAvailableDataTypesV2(this.getSensorObjByID(sensorID));
      } else {
        return [];
      }
    },
    removeSource(index) {
      if (index > -1) {
        this.tempStation.sources.splice(index, 1);
      }
    },
    addSource() {
      this.tempStation.sources.push({ customID: "", sensor: this.actualSensors[0]._id, dataType: getAvailableDataTypesV2(this.actualSensors[0])[0] });
    },
  },
  mounted() {
    this.tempStation = JSON.parse(JSON.stringify(this.value));
  },
  computed: {
    hasDuplicatedCustomIDs() {
      // Controllo che non ci siamo customID duplicati
      const uniqueIDs = new Set(this.tempStation.sources.map((v) => v.customID));
      return uniqueIDs.size < this.tempStation.sources.length;
    },
    hasAlmostOneSource() {
      return this.tempStation.sources.length > 0;
    },
    checkSourcesValidation() {
      return !this.tempStation.sources.some((src) => !src.customID || !src.dataType || !src.sensor);
    },
    checkStationValidation() {
      return this.hasAlmostOneSource && !this.hasDuplicatedCustomIDs && this.checkSourcesValidation;
    },
  },
  watch: {
    tempStation: {
      // Ad ogni cambiamento emetto l'evento per il v-model
      handler: function (newValue, oldValue) {
        /* Object.keys(this.tempStation).map((key) => {
          if (this.tempStation[key] === "" && key !== "mesUnit") {
            delete this.tempStation[key];
          }
        }); */
        this.tempStation["validationStatus"] = this.checkStationValidation;
        this.$emit("input", this.tempStation);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
#main {
  overflow: scroll;
}
label {
  padding-right: 8px;
}
.warning {
  color: yellow;
}
.error {
  color: red;
}
.success {
  color: green;
}
.nested-card {
  border: solid 1px gray;
}
</style>
