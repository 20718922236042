var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"card-body-classes":"table-full-width"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"autocomplete":"do-not-autofill"}},[_c('input',{staticStyle:{"display":"none"},attrs:{"type":"text","name":"fakeusernameremembered"}}),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"password","name":"fakepasswordremembered"}}),_c('card',{attrs:{"footer-classes":"text-right"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Dettagli cliente")])]),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-md-12",attrs:{"name":"username","rules":{ required: true, regex: /^[a-zA-Z0-9_-]{3,16}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed || !_vm.checkIfUsernameIsAvailable }],attrs:{"required":"","type":"username","label":"Username"},model:{value:(_vm.userObj.username),callback:function ($$v) {_vm.$set(_vm.userObj, "username", $$v)},expression:"userObj.username"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"name":"first-name","rules":{ required: true, regex: /^[a-zA-Z ,.'-]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"first-name","label":"Nome","error":errors[0]},model:{value:(_vm.userObj.firstName),callback:function ($$v) {_vm.$set(_vm.userObj, "firstName", $$v)},expression:"userObj.firstName"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"name":"last-name","rules":{ required: true, regex: /^[a-zA-Z ,.'-]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","autocomplete":"new-password","type":"last-name","label":"Cognome","error":errors[0]},model:{value:(_vm.userObj.lastName),callback:function ($$v) {_vm.$set(_vm.userObj, "lastName", $$v)},expression:"userObj.lastName"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"name":"password","rules":{
                  required: true,
                  confirmed: 'confirmation',
                  regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                },"custom-messages":{ regex: 'Minimo 6 caratteri ed un numero', confirmed: _vm.$t('validation.pswEquals') }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","autocomplete":"new-password","type":"password","label":"Inserisci Password","error":errors[0]},model:{value:(_vm.userObj.password),callback:function ($$v) {_vm.$set(_vm.userObj, "password", $$v)},expression:"userObj.password"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"name":"confirm","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","autocomplete":"new-password","type":"password","label":"Conferma Password","error":errors[0]},model:{value:(_vm.pswConfirmation),callback:function ($$v) {_vm.pswConfirmation=$$v},expression:"pswConfirmation"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"name":"email","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"email","label":"Email","error":errors[0]},model:{value:(_vm.userObj.email),callback:function ($$v) {_vm.$set(_vm.userObj, "email", $$v)},expression:"userObj.email"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"name":"phone","rules":{ regex: /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"tel","label":"Phone","error":errors[0]},model:{value:(_vm.userObj.phone),callback:function ($$v) {_vm.$set(_vm.userObj, "phone", $$v)},expression:"userObj.phone"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"name":"company","rules":{ regex: /^[a-zA-Z0-9 ,.'-]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","label":"Company","error":errors[0]},model:{value:(_vm.userObj.company),callback:function ($$v) {_vm.$set(_vm.userObj, "company", $$v)},expression:"userObj.company"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-8",attrs:{"name":"address","rules":{ regex: /^[a-zA-Z0-9 ,.'-]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","label":"Indirizzo","error":errors[0]},model:{value:(_vm.userObj.address),callback:function ($$v) {_vm.$set(_vm.userObj, "address", $$v)},expression:"userObj.address"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"name":"VAT","rules":{ regex: /^[0-9]{11}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","label":"VAT","error":errors[0]},model:{value:(_vm.userObj.VAT),callback:function ($$v) {_vm.$set(_vm.userObj, "VAT", $$v)},expression:"userObj.VAT"}})]}}],null,true)}),_c('div',{staticClass:"col-8"},[_c('label',[_vm._v("Tipo di abbonamento")]),_c('el-select',{staticClass:"select-info wide",attrs:{"placeholder":"Value type"},model:{value:(_vm.userObj.subscriptionType),callback:function ($$v) {_vm.$set(_vm.userObj, "subscriptionType", $$v)},expression:"userObj.subscriptionType"}},_vm._l((['standard', 'pro']),function(subscType){return _c('el-option',{key:subscType,staticClass:"select-info",attrs:{"value":subscType,"label":subscType}})}),1)],1),_c('div',{staticClass:"col-md-12 category form-category"},[_vm._v("* Required fields")])],1),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"type":"primary","disabled":invalid || !_vm.checkIfUsernameIsAvailable},on:{"click":_vm.addUser}},[_vm._v("Aggiungi")])],1)],2)],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }