var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-12 pb-3"},[_c('card',{staticClass:"h-100",attrs:{"card-body-classes":"table-full-width"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"h-100",attrs:{"autocomplete":"do-not-autofill"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',{staticClass:"h-100",attrs:{"footer-classes":"text-right"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Dettagli cliente")])]),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"name":"fn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"text","label":"Nome","error":errors[0]},on:{"input":function($event){_vm.unsavedChanges = true}},model:{value:(_vm.userObj.firstName),callback:function ($$v) {_vm.$set(_vm.userObj, "firstName", $$v)},expression:"userObj.firstName"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"name":"ln","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"text","label":"Cognome","error":errors[0]},on:{"input":function($event){_vm.unsavedChanges = true}},model:{value:(_vm.userObj.lastName),callback:function ($$v) {_vm.$set(_vm.userObj, "lastName", $$v)},expression:"userObj.lastName"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"name":"password","rules":{
                  confirmed: 'confirmation',
                  regex: /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/,
                },"custom-messages":{ regex: 'Minimo 7 caratteri, almeno un numero ed un carattere speciale.', confirmed: _vm.$t('validation.pswEquals') }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"password","label":"Cambio Password","error":errors[0]},on:{"input":function($event){_vm.unsavedChanges = true}},model:{value:(_vm.userObj.password),callback:function ($$v) {_vm.$set(_vm.userObj, "password", $$v)},expression:"userObj.password"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"name":"confirm","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"password","label":"Conferma Password","error":errors[0]},on:{"input":function($event){_vm.unsavedChanges = true}},model:{value:(_vm.pswConfirmation),callback:function ($$v) {_vm.pswConfirmation=$$v},expression:"pswConfirmation"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"name":"email","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"email","label":"Email","error":errors[0]},on:{"input":function($event){_vm.unsavedChanges = true}},model:{value:(_vm.userObj.email),callback:function ($$v) {_vm.$set(_vm.userObj, "email", $$v)},expression:"userObj.email"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"name":"company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","label":"Company","error":errors[0]},on:{"input":function($event){_vm.unsavedChanges = true}},model:{value:(_vm.userObj.company),callback:function ($$v) {_vm.$set(_vm.userObj, "company", $$v)},expression:"userObj.company"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-8",attrs:{"name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","label":"Indirizzo","error":errors[0]},on:{"input":function($event){_vm.unsavedChanges = true}},model:{value:(_vm.userObj.address),callback:function ($$v) {_vm.$set(_vm.userObj, "address", $$v)},expression:"userObj.address"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"name":"VAT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","label":"VAT","error":errors[0]},on:{"input":function($event){_vm.unsavedChanges = true}},model:{value:(_vm.userObj.VAT),callback:function ($$v) {_vm.$set(_vm.userObj, "VAT", $$v)},expression:"userObj.VAT"}})]}}],null,true)}),_c('div',{staticClass:"col-12 category form-category"},[_vm._v("* Required fields")])],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"col-md-12 footer-col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6",attrs:{"align":"center"}},[_c('base-button',{staticClass:"btn-full",attrs:{"type":"default"},on:{"click":_vm.undo}},[_vm._v(" "+_vm._s(_vm.$t("deviceSettings.undo"))+" ")])],1),_c('div',{staticClass:"col-md-6",attrs:{"align":"center"}},[_c('base-button',{staticClass:"btn-full",attrs:{"type":"warning","disabled":invalid || !_vm.unsavedChanges},on:{"click":_vm.save}},[_c('i',{staticClass:"tim-icons icon-settings"}),_vm._v(" "+_vm._s(_vm.$t("deviceSettings.save"))+" ")])],1)])])])],2)],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }