<template>
  <div class="row h-100">
    <div class="col-12 pb-3">
      <div id="wrap" class="extended-forms">
        <!-- Device settings modal -->
        <el-dialog :visible.sync="showSensorSettingsModal" class="min-dialog">
          <span slot="title"
            ><SensorSettingsModal @close="showSensorSettingsModal = false" v-if="showSensorSettingsModal && sensorObj" :sensor="sensorObj" @save="saveSensorSettings"
          /></span>
          <span></span>
          <span slot="footer" class="dialog-footer"> </span>
        </el-dialog>
        <ValidationObserver v-slot="{ invalid, dirty }" v-if="sensorObj">
          <form>
            <card>
              <h4 slot="header" class="card-title">Riepilogo Sensore</h4>
              <div class="col-12">
                <h3 class="card-title">{{ sensorObj.device }}</h3>
                <div>
                  <h4 :style="{ color: getOnline(sensorObj) ? 'green' : 'red' }">
                    Ultima trasmissione: {{ sensorObj.lastTX ? new Date(sensorObj.lastTX).toLocaleString() : "Never" }}
                  </h4>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-progress label="Batteria" value-position="right" :value="getBL(sensorObj)" />
                  </div>
                  <div class="col-md-6">
                    <base-progress label="Segnale" value-position="right" :value="getSQ(sensorObj) || 'N.D.'" />
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="name" rules="required|min:3" v-slot="{ passed, failed, errors }">
                      <base-input
                        @input="unsavedChanges = true"
                        required
                        v-model="sensorObj.userConfig.name"
                        label="Nome custom"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                      >
                      </base-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="number" rules="required|numeric" v-slot="{ passed, failed, errors }">
                      <base-input
                        @input="unsavedChanges = true"
                        required
                        v-model="sensorObj.keepAlive"
                        label="Keep alive (sec.)"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                      >
                      </base-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12">
                    <!-- Impostazioni -->
                    <div class="row">
                      <base-button :wide="true" :block="true" :simple="false" type="info" @click="openInputModal(undefined)">Impostazioni vanzate</base-button>
                    </div>
                  </div>
                  <!-- <div class="col-md-6 mt-4">
                <label>Input configurati:</label>
                <p class="strong ml-2" :style="{ color: input.enabled ? 'green' : 'gray' }" v-for="(input, index) in sensorObj.inputs" :key="index">
                  {{ translateValueType(input.source) + (input.destination ? " → " + translateValueType(input.destination) + " (derivato)" : " (nativo)") + (!input.enabled ? " (off)" : "")}}
                </p>
              </div>
              <div class="col-md-6 mt-4">
                <label>Contatori configurati:</label>
                <p class="strong ml-2" :style="{ color: counter.enabled ? 'green' : 'gray' }" v-for="(counter, index) in sensorObj.counters" :key="index">
                  {{ translateValueType(counter.valueType) + (!counter.enabled ? " (off)" : "") }}
                </p>
              </div> -->
                </div>
              </div>
              <template slot="footer">
                <div class="row">
                  <div class="col-6 flex">
                    <base-button class="auto-m" type="success" :disabled="!unsavedChanges || invalid" @click="saveQuick">Salva </base-button>
                  </div>
                  <div class="col-6 flex">
                    <base-button class="auto-m" type="warning" :disabled="!dirty" @click="undo">Annulla</base-button>
                  </div>
                </div>
              </template>
            </card>
          </form>
        </ValidationObserver>
        <div v-else class="empty-module">
          <h3>Aggiungi/seleziona un sensore...</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { TimeSelect, DatePicker, Select, Option, Dialog } from "element-ui";
import { BaseProgress } from "src/components/index";
import { isOnline, getNormalizedBatteryLvl, getNormalizedQualitySignal } from "../../../helpers/device";
import { translateDataType } from "../../../helpers/translation";
import SensorSettingsModal from "./SensorSettingsModal.vue";

export default {
  components: {
    SensorSettingsModal,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    BaseProgress,
  },
  data() {
    return {
      showSensorSettingsModal: false,
      unsavedChanges: false,
      sensorObj: null,
      enabledRadio: "2",
      disabledRadio: "2",
      images: {
        regular: null,
        avatar: null,
      },
    };
  },
  computed: {
    ...mapGetters({ sensor: "admin/getSelectedSensorObj" }),
  },
  methods: {
    ...mapActions({ putSensorToCustomer: "admin/putSensorToCustomer" }),
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    openInputModal(input) {
      this.showSensorSettingsModal = true;
    },
    translateValueType(valueType) {
      return translateDataType(valueType);
    },
    onImageChange(file) {
      this.images.regular = file;
    },
    onAvatarChange(file) {
      this.images.avatar = file;
    },
    getSQ(sensor) {
      return getNormalizedQualitySignal(sensor);
    },
    getBL(sensor) {
      return getNormalizedBatteryLvl(sensor);
    },
    getOnline(sensor) {
      return isOnline(sensor);
    },
    undo() {
      this.sensorObj = JSON.parse(JSON.stringify(this.sensor));
    },
    async saveQuick() {
      //this.$emit("save", this.sensorObj);
      console.log("Save Quick", this.sensorObj);
      this.startLoading();
      await this.putSensorToCustomer(this.sensorObj);
      this.stopLoading();
    },
    async saveSensorSettings(sensorObj) {
      //this.$emit("save", this.sensorObj);
      console.log("Save Full", sensorObj);
      this.startLoading();
      await this.putSensorToCustomer(sensorObj);
      this.stopLoading();
    },
  },
  watch: {
    sensor(newValue) {
      if (newValue) {
        this.sensorObj = JSON.parse(JSON.stringify(newValue));
      } else {
        this.sensorObj = null;
      }
    },
  },
};
</script>
<style scoped>
#wrap,
.card,
form {
  height: 100%;
}
.empty-module {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
