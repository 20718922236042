<template>
  <card v-if="tempDevice" id="main-wrap">
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <template slot="header">
        <h5 class="card-category">{{ tempDevice.device }}</h5>
        <h3 class="card-title">{{ $t("deviceSettings.deviceSettings") }}</h3>
      </template>
      <tabs type="info" :centered="false">
        <tab-pane :label="$t('deviceSettings.general')">
          <form @submit.prevent="handleSubmit(submit)">
            <div>
              <ValidationProvider name="devicename" rules="required|min:1" v-slot="{ passed, failed, errors }">
                <base-input v-model.trim="tempDevice.userConfig.name" :label="$t('deviceSettings.deviceName')"
                  :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
              <ValidationProvider v-if="tempDevice.inputs && tempDevice.inputs.some((inp) => inp.source === 'distance')"
                name="filterthreshold" :rules="{
                  required: true,
                  regex: /^-?\d*\.{0,1}\d+$/,
                }" v-slot="{ passed, failed, errors }">
                <base-input v-model="tempDevice.userConfig.maxDistFilterThreshold" type="number"
                  :label="$t('deviceSettings.distanceFilterDesc')" :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
              <div style="margin-top: 12px"
                v-if="tempDevice.inputs && tempDevice.inputs.some((inp) => inp.source === 'distance')">
                <label>
                  {{ $t("deviceSettings.offsetValue") }}: <span class="strong">{{ tempDevice.userConfig.offset }}
                    cm.</span>
                </label>
              </div>
              <div class="grid" v-if="tempDevice.inputs && tempDevice.inputs.some((inp) => inp.source === 'distance')">
                <label class="category">{{ $t("deviceSettings.allowDistancesLessThanOffset") }}</label>
                <base-switch type="info" on-text="ON" off-text="OFF"
                  v-model="tempDevice.userConfig.onlyPositiveDistValues"></base-switch>
              </div>
            </div>
            <template slot="footer"> </template>
          </form>
        </tab-pane>

        <tab-pane v-if="showPublicDataLink" :label="$t('deviceSettings.publicData')">
          <div class="qr-code-container" style="margin-top: 44px">
            <a :href="publicDataLink" target="_blank" rel="noopener noreferrer">{{ $t("deviceSettings.publicDataLink")
            }}:</a>
            <QRCode :text="publicDataLink" />
          </div>
        </tab-pane>

        <tab-pane :label="$t('deviceSettings.alertThresholds')" v-if="hasAlertsFeature">
          <div class="row">
            <div class="col-md-12">
              <div class="flex-setting">
                <label>{{ $t("deviceSettings.notificationsAccounts") }}:</label>
                <el-select filterable multiple class="select-info" v-model="tempDevice.userConfig.alertsUsers"
                  placeholder="Account">
                  <el-option v-for="alertUser in user.alertsUsers" class="select-info" :value="alertUser._id"
                    :label="alertUser.name" :key="alertUser._id"> </el-option>
                </el-select>
              </div>
              <card card-body-classes="table-full-width">
                <template slot="header"> </template>
                <div>
                  <base-button id="add-alert-button" size="sm" type="info" class="button add-button" menu-on-right=""
                    tag="div" title-classes="btn btn-link btn-icon" :class="{ 'float-left': $rtl.isRTL }"
                    @click="addAlertThrehshold">
                    <i class="tim-icons icon-simple-add"></i>
                  </base-button>
                  <el-table :data="queriedData" max-height="300" style="width: 100%; min-width: 900px">
                    <!-- Value Type -->
                    <el-table-column min-width="100" sortable :label="$t('deviceSettings.dataType')" property="valueType"
                      align="center">
                      <template slot-scope="{ row }">
                        <el-select class="select-info" size="small"
                          :placeholder="$t('deviceSettings.notificationsAccounts')" v-model="row.valueType">
                          <el-option v-for="option in [...getDataTypesList]" class="select-info" :value="option"
                            :label="translateValueType(option)" :key="option"> </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column
                    min-width="60"
                    sortable
                    :label="$t('deviceSettings.channel')"
                    property="channel"
                    align="center"
                    v-if="!tempDevice.userConfig.alerts.some((al) => al.channel)"
                  >
                    <template slot-scope="{ row }">
                      <el-select class="select-info" size="small" :placeholder="$t('deviceSettings.channel')" v-model="row.channel" :disabled="row.alertType != 'generic'">
                        <el-option v-for="option in [0, 1, 2, 3]" class="select-info" :value="option" :label="option" :key="option"> </el-option>
                      </el-select>
                    </template>
                  </el-table-column> -->
                    <el-table-column min-width="90" sortable :label="$t('deviceSettings.alertType')" property="alertType"
                      align="center">
                      <template slot-scope="{ row }">
                        <el-select class="select-info" size="small" :placeholder="$t('deviceSettings.alertType')"
                          v-model="row.alertType">
                          <el-option v-for="option in ['up', 'down']" class="select-info" :value="option" :label="option"
                            :key="option"> </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="100" sortable :label="$t('deviceSettings.threshold')" property="threshold"
                      align="center">
                      <template slot-scope="{ row }">
                        <ValidationProvider name="threshold" :rules="{
                          required: true,
                          regex: /^-?\d*\.{0,1}\d+$/,
                        }" v-slot="{ passed, failed }">
                          <base-input required v-model="row.threshold"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]"> </base-input>
                        </ValidationProvider>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="40" :label="$t('deviceSettings.mail')" align="center">
                      <template slot-scope="{ row }">
                        <base-checkbox v-model="row.notifications" value="email"></base-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="40" :label="$t('deviceSettings.sms')" align="center">
                      <template slot-scope="{ row }">
                        <base-checkbox v-model="row.notifications" value="sms"></base-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="40" :label="$t('deviceSettings.wa')" align="center">
                      <template>
                        <base-checkbox value="wa" disabled></base-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="60" :label="$t('deviceSettings.enabled')" align="center">
                      <template slot-scope="{ row }">
                        <base-switch v-model="row.enabled" type="info" on-text="ON" off-text="OFF"></base-switch>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="50" :label="$t('deviceSettings.status')" align="center">
                      <template slot-scope="{ row }">
                        <i :style="{ color: row.status ? 'var(--warning)' : 'var(--success)' }"
                          :class="[row.status ? 'icon-alert-circle-exc' : 'icon-check-2', 'tim-icons']"></i>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="50" label="" align="left">
                      <template slot-scope="scope">
                        <base-button type="danger" class="btn-link" @click="deleteAlertThrehshold(scope.$index)">
                          <i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                  <div class="">
                    <p class="card-category">{{ $t("table.showing") }} {{ from + 1 }} {{ $t("table.to") }} {{ to }} {{
                      $t("table.of") }} {{ total }} {{ $t("table.entries") }}</p>
                  </div>
                  <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                    :per-page="pagination.perPage" :total="total"> </base-pagination>
                </div>
              </card>
            </div>
          </div>
        </tab-pane>
      </tabs>
      <div class="col-md-12 footer-col">
        <div class="row">
          <div class="col-md-6" align="center">
            <base-button type="default" class="btn-full" @click="closeMe"> <i class="tim-icons icon-square-pin"></i> {{
              $t("deviceSettings.undo") }} </base-button>
          </div>
          <div class="col-md-6" align="center">
            <base-button type="success" class="btn-full" @click="saveDeviceSettings" :disabled="invalid">
              <i class="tim-icons icon-settings"></i> {{ $t("deviceSettings.save") }}
            </base-button>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { TabPane, Tabs } from "src/components";
import { BasePagination } from "src/components";
import { BaseCheckbox, BaseSwitch } from "src/components/index";
import { Select, Option } from "element-ui";
/* import { configure } from "vee-validate"; */
import { Table, TableColumn } from "element-ui";
import { getAvailableDataTypesV2 } from "../helpers/device";
import { translateDataType } from "../helpers/translation";
import { patchSensor } from "../services/api";
import QRCode from "./QRCode.vue";

export default {
  name: "DeviceSettings",
  components: {
    BasePagination,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    TabPane,
    Tabs,
    BaseCheckbox,
    BaseSwitch,
    QRCode
  },
  data() {
    return {
      tempDevice: null,
      tempAlerts: [],
      pagination: {
        perPage: 3,
        currentPage: 1,
        perPageOptions: [2, 4, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["payload.device"],
      searchedData: [],
      fuseSearch: null,
    };
  },
  methods: {
    ...mapActions({
      fetchUserInfo: "user/fetchUserInfo",
    }),
    translateValueType(valueType) {
      return translateDataType(valueType);
    },
    deleteAlertThrehshold(index) {
      this.tempDevice.userConfig.alerts.splice(index, 1);
    },
    closeMe() {
      this.$emit("close");
    },
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    async saveDeviceSettings() {
      this.startLoading();
      await patchSensor(this.tempDevice._id, { userConfig: this.tempDevice.userConfig });
      await this.fetchUserInfo();
      this.stopLoading();
      this.closeMe();
    },
    addAlertThrehshold() {
      const newAl = {
        valueType: this.getDataTypesList[0],
        alertType: "up",
        notifications: ["email"],
        status: false,
        enabled: false,
        threshold: 0,
      };
      if (newAl.valueType === "generic") {
        newAl.channel = 0;
      }
      this.tempDevice.userConfig.alerts.push(newAl);
    },
  },
  computed: {
    ...mapGetters({ device: "dashboard/getSelectedDeviceObj", user: "user/getUserObj", publicDataLink: "dashboard/getPublicDataLink" }),
    showPublicDataLink() {
      return this.publicDataLink && this.device.inputs.some((inp) => inp.publicLastValue === true);
    },
    // Faccio vedere gli allarmi SOLO se non è una telecamera
    hasAlertsFeature() {
      return this.device && !this.device.staticType;
    },
    getDataTypesList() {
      return getAvailableDataTypesV2(this.device);
    },
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tempDevice.userConfig.alerts;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tempDevice.userConfig.alerts.length;
    },
  },
  watch: {
    device: function (newDevice, oldDevice) {
      if (newDevice) {
        this.tempDevice = JSON.parse(JSON.stringify(newDevice));
      }
      console.log("Device Settings Watch", newDevice);
    },
  },
  mounted() {
    this.tempDevice = JSON.parse(JSON.stringify(this.device));
  },
};
</script>
<style scoped>
.select-info {
  width: 100%;
  max-width: 100%;
}

.bootstrap-switch {
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 0;
}

.has-success:after,
.has-danger:after {
  top: 8px;
}

.add-button {
  padding: 4px;
  /* position: absolute; */
  right: 0;
  margin: 6px 12px;
  z-index: 2;
}

#main-wrap {
  min-height: 500px;
}

.footer-col {
  height: fit-content;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 20px;
}

/* .flex-setting {
  display: grid;
  grid-template-columns: max-content 10fr;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
} */
#add-alert-button {
  position: absolute;
  top: 20px;
  right: 27px;
}

.qr-code-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a {
  font-weight: 600;
  font-size: 1.2em;
  margin-bottom: 6px;
  display: block;
}
</style>
