<template>
  <ValidationObserver v-if="outputObj" v-slot="{ invalid }">
    <card id="main-wrap">
      <template slot="header">
        <h5 class="card-category">Dettagli output</h5>
      </template>

      <tabs type="primary">
        <tab-pane label="Generali">
          <div class="row">
            <!-- Nome -->
            <div class="col-6 mt-2">
              <ValidationProvider name="name" rules="required|min:3" v-slot="{ passed, failed }">
                <base-input @input="unsavedChanges = true" required v-model="outputObj.name" label="Nome custom" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <!-- Host -->
            <div class="col-6 mt-2">
              <ValidationProvider name="host" rules="required|min:3" v-slot="{ passed, failed }">
                <base-input @input="unsavedChanges = true" required v-model="outputObj.host" label="Host" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <!-- Provincia -->
            <div class="col-6 mt-2">
              <ValidationProvider name="provincia" rules="required|length:2" v-slot="{ passed, failed }">
                <base-input @input="unsavedChanges = true" required v-model="outputObj.provincia" label="Provincia" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <!-- Base Dir -->
            <div class="col-6 mt-2">
              <ValidationProvider name="baseDir" rules="min:2" v-slot="{ passed, failed }">
                <base-input @input="unsavedChanges = true" v-model="outputObj.baseDir" label="Base Dir" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <!-- Username -->
            <div class="col-6 mt-2">
              <ValidationProvider name="username" rules="required|min:2" v-slot="{ passed, failed }">
                <base-input @input="unsavedChanges = true" required v-model="outputObj.username" label="Username" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <!-- Password -->
            <div class="col-6 mt-2">
              <ValidationProvider name="password" rules="required|min:2" v-slot="{ passed, failed }">
                <base-input @input="unsavedChanges = true" required v-model="outputObj.password" label="Password" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <!-- Abilitato -->
            <div class="col-12 flex mt-4" v-if="outputObj.enabled !== undefined">
              <label class="mr-2">Output abilitato:</label>
              <base-switch type="info" on-text="ON" off-text="OFF" v-model="outputObj.enabled"></base-switch>
            </div>
          </div>
        </tab-pane>

        <tab-pane label="Stazioni">
          <tabs type="info" vertical class="row">
            <!-- Itero sulle Stations dell'Output-->
            <tab-pane v-for="(station, index) in outputObj.stations" :key="index + inputRefreshToken.toString()" :label="station.stationID">
              <div class="col-12">
                <Station v-model="outputObj.stations[index]" :stationIndex="index" :actualSensors="sensors" @remove-me="removeStation(index)" />
              </div>
            </tab-pane>
          </tabs>
          <!-- Aggiungi input -->
          <div class="col-4">
            <div class="row mr-2">
              <base-button class="col-4 auto-m" size="sm" :wide="false" :block="true" :simple="false" type="info" @click="addStation" round :disabled="someInvalidStation"
                >Aggiungi</base-button
              >
            </div>
          </div>
        </tab-pane>
      </tabs>
      <template slot="footer">
        <div class="row">
          <div class="col-6 flex">
            <base-button size="sm" type="success" class="auto-m" @click="closeMe">Chiudi</base-button>
          </div>
          <div class="col-6 flex">
            <base-button size="sm" type="primary" class="auto-m" :disabled="invalid || someInvalidStation || !almostOneStation" @click="save">Salva</base-button>
          </div>
        </div>
      </template>
    </card>
  </ValidationObserver>
</template>
<script>
import { TabPane, Tabs } from "src/components";
import { Table, TableColumn } from "element-ui";
import { Select, Option } from "element-ui";
import { BaseSwitch } from "src/components/index";
import Station from "./OutputSettingsComponents/Station.vue";

export default {
  name: "OutputModal",
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseSwitch,
    TabPane,
    Tabs,
    Station,
  },
  props: {
    output: Object,
    sensors: Array,
  },
  data() {
    return {
      outputObj: null,
      inputRefreshToken: Math.random(),
      counterRefreshToken: Math.random(),
    };
  },
  methods: {
    save() {
      const outputObjToSend = _.cloneDeep(this.outputObj);
      (outputObjToSend.stations || []).forEach((sta) => {
        delete sta.validationStatus;
        sta.sources.forEach((srcs) => {
          delete srcs.validationStatus;
        });
      });
      this.$emit("save", outputObjToSend);
      this.closeMe();
    },
    /***********/
    closeMe() {
      this.$emit("close");
    },
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    addStation() {
      this.outputObj.stations.push({
        stationID: "STATION_" + (this.outputObj.stations.length + 1),
        sources: [
          {
            sensor: null,
            customID: null,
            dataType: null,
          },
        ],
      });
    },
    removeStation(index) {
      if (index > -1) {
        this.outputObj.stations.splice(index, 1);
        this.inputRefreshToken++;
      }
    },
  },
  computed: {
    almostOneStation() {
      return this.outputObj.stations.length > 0;
    },
    someInvalidStation() {
      // Controllo che non ci siamo stationID duplicati
      const uniqueIDs = new Set(this.outputObj.stations.map((v) => v.stationID));
      const hasDuplicates = uniqueIDs.size < this.outputObj.stations.length;
      return hasDuplicates || this.outputObj.stations.some((inp) => inp.validationStatus === false);
    },
  },
  mounted() {
    if (this.output) {
      this.outputObj = JSON.parse(JSON.stringify(this.output));
    } else {
      this.outputObj = {
        type: "ftp",
        name: "FTP Output",
        host: null,
        baseDir: "",
        username: null,
        password: null,
        dataFormat: "Regione Toscana",
        provincia: null,
        enabled: false,
        stations: [],
      };
    }
  },
};
</script>
<style scoped>
.select-info {
  width: 100%;
  max-width: 100%;
}
.bootstrap-switch {
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 0;
}
.has-success:after,
.has-danger:after {
  top: 8px;
}
.add-button {
  padding: 4px;
  right: 0;
  margin: 6px 12px;
  z-index: 2;
}
#main-wrap {
  min-height: 500px;
}
.footer-col {
  height: fit-content;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 20px;
}
#add-alert-button {
  position: absolute;
  top: 20px;
  right: 27px;
}
</style>
