var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mr-2"},[_c('div',{staticClass:"col-6 p-4"},[_c('div',{staticClass:"col-12 pl-0 flex"},[_c('label',[_vm._v("TCP ModBus abilitato:")]),_c('base-switch',{attrs:{"type":"info","on-text":"ON","off-text":"OFF"},model:{value:(_vm.sensorObj.modBus.enabled),callback:function ($$v) {_vm.$set(_vm.sensorObj.modBus, "enabled", $$v)},expression:"sensorObj.modBus.enabled"}})],1),_c('label',[_vm._v("Sample Rate")]),_c('el-select',{staticClass:"select-info",attrs:{"placeholder":"Sample Rate"},model:{value:(_vm.sensorObj.modBus.sampleRate),callback:function ($$v) {_vm.$set(_vm.sensorObj.modBus, "sampleRate", $$v)},expression:"sensorObj.modBus.sampleRate"}},_vm._l(([10, 30, 60]),function(val){return _c('el-option',{key:val,staticClass:"select-info",attrs:{"value":val,"label":val + ' [min]'}})}),1),_c('ValidationProvider',{attrs:{"name":"host","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","label":"Host"},model:{value:(_vm.sensorObj.modBus.host),callback:function ($$v) {_vm.$set(_vm.sensorObj.modBus, "host", $$v)},expression:"sensorObj.modBus.host"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"port","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"number","label":"Port"},model:{value:(_vm.sensorObj.modBus.port),callback:function ($$v) {_vm.$set(_vm.sensorObj.modBus, "port", $$v)},expression:"sensorObj.modBus.port"}})]}}])})],1),_c('div',{staticClass:"col-6 p-4"},[_c('div',{staticClass:"row mr-2"},[_c('base-button',{staticClass:"col-4 auto-m",attrs:{"size":"sm","wide":false,"block":true,"simple":false,"type":"info","round":""},on:{"click":_vm.addAddress}},[_vm._v("Aggiungi indirizzo")])],1),_c('div',{staticClass:"list"},_vm._l((_vm.sensorObj.modBus.map),function(add,index){return _c('div',{key:index,staticClass:"row mr-2"},[_c('div',{staticClass:"col-4 centered"},[_c('label',[_vm._v("Abilitato")]),_c('base-switch',{attrs:{"type":"info","on-text":"ON","off-text":"OFF"},model:{value:(add.enabled),callback:function ($$v) {_vm.$set(add, "enabled", $$v)},expression:"add.enabled"}})],1),_c('div',{staticClass:"col-4"},[_c('ValidationProvider',{attrs:{"name":"address","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"number","label":"Address"},model:{value:(add.address),callback:function ($$v) {_vm.$set(add, "address", $$v)},expression:"add.address"}})]}}],null,true)})],1),_c('div',{staticClass:"col-4"},[_c('label',[_vm._v("Source")]),_c('el-select',{staticClass:"select-info",attrs:{"placeholder":"Source"},model:{value:(add.input),callback:function ($$v) {_vm.$set(add, "input", $$v)},expression:"add.input"}},_vm._l((_vm.sensorData),function(newSource){return _c('el-option',{key:newSource,staticClass:"select-info",attrs:{"value":newSource,"label":_vm.translateDataType(newSource)}})}),1)],1)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }