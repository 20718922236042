var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('route-bread-crumb')],1),_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Table with Links")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('el-table',{attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"min-width":"50","label":"#","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":row.img,"alt":"Table image"}})])}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"name","label":"Name"}}),_c('el-table-column',{attrs:{"min-width":"200","prop":"job","label":"Job Position"}}),_c('el-table-column',{attrs:{"min-width":"150","prop":"since","align":"center","label":"Since"}}),_c('el-table-column',{attrs:{"min-width":"150","prop":"salary","align":"right","label":"Salary"}}),_c('el-table-column',{attrs:{"min-width":"150","header-align":"right","align":"right","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
return _c('div',{staticClass:"text-right table-actions"},[_c('el-tooltip',{attrs:{"content":"Refresh","effect":"light","open-delay":300,"placement":"top"}},[_c('base-button',{staticClass:"btn-link",attrs:{"type":$index > 2 ? 'success' : 'neutral',"icon":"","size":"sm"}},[_c('i',{staticClass:"tim-icons icon-refresh-01"})])],1),_c('el-tooltip',{attrs:{"content":"Delete","effect":"light","open-delay":300,"placement":"top"}},[_c('base-button',{staticClass:"btn-link",attrs:{"type":$index > 2 ? 'danger' : 'neutral',"icon":"","size":"sm"}},[_c('i',{staticClass:"tim-icons icon-simple-remove"})])],1)],1)}}])})],1)],1)])]),_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Striped Table with Checkboxes")])]),_c('div',{staticClass:"row table-full-width"},[_c('div',{staticClass:"col-sm-12"},[_c('el-table',{attrs:{"data":_vm.secondTableData}},[_c('el-table-column',{attrs:{"width":"60","label":"#","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"checkbox-cell"},[_c('base-checkbox',{model:{value:(row.checked),callback:function ($$v) {_vm.$set(row, "checked", $$v)},expression:"row.checked"}})],1)}}])}),_c('el-table-column',{attrs:{"prop":"name","min-width":"180","label":"Product Name"}}),_c('el-table-column',{attrs:{"prop":"type","min-width":"180","label":"Type"}}),_c('el-table-column',{attrs:{"label":"Milestone","min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('base-progress',{attrs:{"size":'bg',"value":row.progress,"label":row.progressText,"value-position":"right"}})],1)}}])}),_c('el-table-column',{attrs:{"prop":"quantity","min-width":"80","align":"center","label":"QTY"}}),_c('el-table-column',{attrs:{"prop":"price","min-width":"120","align":"right","label":"Price"}}),_c('el-table-column',{attrs:{"prop":"amount","min-width":"120","align":"right","label":"Amount"}})],1)],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Shopping Cart Table")]),_c('br')]),_c('shopping-table')],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }