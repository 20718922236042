<template>
  <div class="row" v-if="tempSource">
    <!-- Custom ID -->
    <div class="col-6 flex">
      <ValidationProvider name="title" v-slot="{ passed, failed }">
        <base-input type="text" v-model="tempSource.customID" label="Custom ID regione" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"></base-input>
      </ValidationProvider>
    </div>
    <!-- Sensore -->
    <div class="col-6 columns">
      <label>Sensore</label>
      <el-select class="select-info" v-model="tempSource.sensor" placeholder="Sensore">
        <el-option v-for="sensor in actualSensors" class="select-info" :value="sensor._id" :label="sensor.device + ' - ' + sensor.userConfig.name" :key="sensor._id"> </el-option>
      </el-select>
    </div>
    <!-- Tipo di dato -->
    <div class="col-6 columns">
      <label>Tipo di dato</label>
      <el-select class="select-info" v-model="tempSource.dataType" placeholder="Sensore">
        <el-option v-for="dataType in getAvailableDataTypes" class="select-info" :value="dataType" :label="translateDataType(dataType)" :key="dataType"> </el-option>
      </el-select>
    </div>
    <!-- Unità di misura -->
    <div class="col-6 columns">
      <label>Unità di misura</label>
      <el-select class="select-info" v-model="tempSource.mesUnit" placeholder="Sensore">
        <el-option v-for="mesUnit in getAvailableMesUnits" class="select-info" :value="mesUnit" :label="mesUnit" :key="mesUnit"> </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Select, Option } from "element-ui";
import { translateDataType } from "src/helpers/translation";
import { getAvailableDataTypesV2, getDefaultMesUnitsByDataType, getMesUnitsByDataType, get } from "src/helpers/device";

export default {
  components: { [Option.name]: Option, [Select.name]: Select },
  props: {
    value: Object,
    actualSensors: Array,
  },
  data() {
    return { tempSource: null };
  },
  methods: {
    translateDataType: translateDataType,
  },
  computed: {
    getSensorObj() {
      return (this.actualSensors || []).find((sn) => sn._id === this.tempSource.sensor);
    },
    getAvailableMesUnits() {
      return getMesUnitsByDataType(this.tempSource.dataType);
    },
    getAvailableDataTypes() {
      if (this.tempSource.sensor) {
        return getAvailableDataTypesV2(this.getSensorObj);
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.tempSource = JSON.parse(JSON.stringify(this.value));
    if (!this.tempSource.mesUnit && this.tempSource.dataType) {
      Vue.set(this.tempSource, "mesUnit", getDefaultMesUnitsByDataType(this.tempSource.dataType));
    }
  },
  watch: {
    tempSource: {
      // Ad ogni cambiamento emetto l'evento per il v-model
      handler: function (newValue, oldValue) {
        this.$emit("input", this.tempSource);
      },
      deep: true,
    },
    "tempSource.dataType": {
      handler: function (newValue, oldValue) {
        if (newValue && oldValue) {
          Vue.set(this.tempSource, "mesUnit", getDefaultMesUnitsByDataType(newValue));
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.columns {
  display: flex;
  flex-direction: column;
}
</style>
