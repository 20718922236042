<template>
  <div class="row">
    <div class="col-12">
      <card card-body-classes="table-full-width">
        <ValidationObserver v-slot="{ invalid }">
          <form autocomplete="do-not-autofill">
            <!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
            <input style="display: none" type="text" name="fakeusernameremembered" />
            <input style="display: none" type="password" name="fakepasswordremembered" />
            <card footer-classes="text-right">
              <div slot="header"><h4 class="card-title">Dettagli cliente</h4></div>
              <div class="row">
                <!-- Username -->
                <ValidationProvider class="col-md-12" name="username" :rules="{ required: true, regex: /^[a-zA-Z0-9_-]{3,16}$/ }" v-slot="{ passed, failed }">
                  <base-input
                    required
                    v-model="userObj.username"
                    type="username"
                    label="Username"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed || !checkIfUsernameIsAvailable }]"
                  >
                  </base-input>
                </ValidationProvider>
                <!-- Nome -->
                <ValidationProvider class="col-md-6" name="first-name" :rules="{ required: true, regex: /^[a-zA-Z ,.'-]+$/ }" v-slot="{ passed, failed, errors }">
                  <base-input
                    required
                    v-model="userObj.firstName"
                    type="first-name"
                    label="Nome"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  ></base-input>
                </ValidationProvider>
                <!-- Cognome -->
                <ValidationProvider class="col-md-6" name="last-name" :rules="{ required: true, regex: /^[a-zA-Z ,.'-]+$/ }" v-slot="{ passed, failed, errors }">
                  <base-input
                    required
                    autocomplete="new-password"
                    v-model="userObj.lastName"
                    type="last-name"
                    label="Cognome"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  ></base-input>
                </ValidationProvider>
                <!-- Password -->
                <ValidationProvider
                  class="col-md-6"
                  name="password"
                  :rules="{
                    required: true,
                    confirmed: 'confirmation',
                    regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                  }"
                  :custom-messages="{ regex: 'Minimo 6 caratteri ed un numero', confirmed: $t('validation.pswEquals') }"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    required
                    autocomplete="new-password"
                    v-model="userObj.password"
                    type="password"
                    label="Inserisci Password"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  ></base-input>
                </ValidationProvider>
                <!-- Conferma Password -->
                <ValidationProvider class="col-md-6" name="confirm" vid="confirmation" v-slot="{ passed, failed, errors }">
                  <base-input
                    required
                    autocomplete="new-password"
                    v-model="pswConfirmation"
                    type="password"
                    label="Conferma Password"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  ></base-input>
                </ValidationProvider>
                <!-- Email -->
                <ValidationProvider class="col-md-6" name="email" rules="email|required" v-slot="{ passed, failed, errors }">
                  <base-input
                    required
                    v-model="userObj.email"
                    type="email"
                    label="Email"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  ></base-input>
                </ValidationProvider>
                <!-- Phone -->
                <ValidationProvider
                  class="col-md-6"
                  name="phone"
                  :rules="{ regex: /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/ }"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input v-model="userObj.phone" type="tel" label="Phone" :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"></base-input>
                </ValidationProvider>
                <!-- Company -->
                <ValidationProvider class="col-md-4" name="company" :rules="{ regex: /^[a-zA-Z0-9 ,.'-]+$/ }" v-slot="{ passed, failed, errors }">
                  <base-input v-model="userObj.company" type="text" label="Company" :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"> </base-input>
                </ValidationProvider>
                <!-- Indirizzo -->
                <ValidationProvider class="col-md-8" name="address" :rules="{ regex: /^[a-zA-Z0-9 ,.'-]+$/ }" v-slot="{ passed, failed, errors }">
                  <base-input
                    v-model="userObj.address"
                    type="text"
                    label="Indirizzo"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  ></base-input>
                </ValidationProvider>
                <!-- VAT -->
                <ValidationProvider class="col-md-4" name="VAT" :rules="{ regex: /^[0-9]{11}$/ }" v-slot="{ passed, failed, errors }">
                  <base-input v-model="userObj.VAT" type="text" label="VAT" :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"> </base-input>
                </ValidationProvider>
                <!-- Tipo di abbonamento -->
                <div class="col-8">
                  <label>Tipo di abbonamento</label>
                  <el-select class="select-info wide" v-model="userObj.subscriptionType" placeholder="Value type">
                    <el-option v-for="subscType in ['standard', 'pro']" class="select-info" :value="subscType" :label="subscType" :key="subscType"> </el-option>
                  </el-select>
                </div>
                <div class="col-md-12 category form-category">* Required fields</div>
              </div>
              <template slot="footer">
                <base-button type="primary" @click="addUser" :disabled="invalid || !checkIfUsernameIsAvailable">Aggiungi</base-button>
              </template>
            </card>
          </form>
        </ValidationObserver>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { Select, Option } from "element-ui";

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      userObj: {}, // Oggetto utente
      //password: null, // Variabili d'appoggio per
      pswConfirmation: null,
    };
  },
  methods: {
    addUser() {
      this.$emit("save", this.userObj);
      console.log("Aggiungo ", this.userObj);
    },
  },
  computed: {
    ...mapGetters({ getUserList: "admin/getUserList" }),
    checkIfUsernameIsAvailable() {
      // Ritorna true lo username attualmente scelto è disponibile
      return !(this.getUserList || []).some((usr) => usr.username === this.userObj.username);
    },
  },
  mounted() {
    // All'inizio setto 'standard' come tipo di abbonamento di default
    Vue.set(this.userObj, "subscriptionType", "standard");
  },
};
</script>
<style scoped>
.wide {
  width: 100%;
}
</style>
