<template>
  <ValidationObserver>
    <div id="main" class="row pt-3" v-if="tempInput">
      <!-- Salva (TODO) -->
      <!-- Warning -->
      <div class="col-12" v-if="!nativeMode && !validateInputDestination(tempInput.destination)">
        <p class="error strong">Errore: Destinazione già presente su un altro input!</p>
      </div>
      <!-- Success -->
      <!-- <div class="col-12" v-if="nativeMode || (!nativeMode && validateInputDestination(tempInput.destination) && this.validateInputSource(this.tempInput.source))">
        <p class="success strong">Impostazioni input corrette :)</p>
      </div> -->
      <collapse :multiple-active="false" class="col-12">
        <collapse-item title="Generali">
          <div class="row pt-3">
            <!-- Abilitato -->
            <div class="col-12 flex" v-if="tempInput.enabled !== undefined">
              <label>Input abilitato:</label>
              <base-switch type="info" on-text="ON" off-text="OFF" v-model="tempInput.enabled"></base-switch>
            </div>
            <!-- Ultimo valore pubblico -->
            <div class="col-12 flex" v-if="tempInput.publicLastValue !== undefined">
              <label>Ultimo valore pubblico abilitato:</label>
              <base-switch type="info" on-text="ON" off-text="OFF" v-model="tempInput.publicLastValue"></base-switch>
            </div>
            <!-- Source -->
            <div class="col-6" v-if="!nativeMode">
              <label>Source</label>
              <el-select class="select-info" v-model="tempInput.source" placeholder="Source" :class="[validateInputSource(tempInput.source) ? '' : 'invalid']">
                <el-option v-for="newSource in availableSources" class="select-info" :value="newSource" :label="translateDataType(newSource)" :key="newSource"> </el-option>
              </el-select>
            </div>
            <!-- Destination -->
            <div class="col-6" v-if="!nativeMode">
              <label>Destination</label>
              <el-select class="select-info" v-model="tempInput.destination" placeholder="Destination" :class="[validateInputDestination(tempInput.destination) ? '' : 'invalid']">
                <el-option v-for="newDest in availableDestinations" class="select-info" :value="newDest" :label="translateDataType(newDest)" :key="newDest"> </el-option>
              </el-select>
            </div>
            <!-- Visualizza -->
            <div class="col-12 flex" v-if="tempInput.show !== undefined">
              <label>Visualizza grafico:</label>
              <base-switch type="info" on-text="ON" off-text="OFF" v-model="tempInput.show"></base-switch>
            </div>
            <!-- Titolo -->
            <div class="col-4 flex">
              <ValidationProvider name="title" v-slot="{ passed, failed }">
                <base-input type="text" v-model="tempInput.title" label="Titolo custom (opt)" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"></base-input>
              </ValidationProvider>
            </div>
            <!-- Label -->
            <div class="col-4 flex">
              <ValidationProvider name="label" v-slot="{ passed, failed, errors }">
                <base-input
                  type="text"
                  v-model="tempInput.label"
                  label="Label custom (opt)"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                ></base-input>
              </ValidationProvider>
            </div>
            <!-- Mes Unit -->
            <div class="col-4">
              <label>Unità di misura</label>
              <el-select class="select-info" v-model="tempInput.mesUnit" placeholder="Mes Unit" v-if="tempInput.mesUnit">
                <el-option v-for="mesUnit in getMesUnitsByDataType(tempInput.destination || tempInput.source)" class="select-info" :value="mesUnit" :label="mesUnit" :key="mesUnit">
                </el-option>
              </el-select>
            </div>

            <div class="col-12">
              <base-button class="auto-m" size="sm" :wide="false" :block="true" :simple="true" type="warning" @click="removeDerivatedInput">Elimina input selezionato</base-button>
            </div>
          </div>
        </collapse-item>

        <collapse-item title="Filtro">
          <div class="row pt-3">
            <!-- Packet simulation -->
            <div class="col-12 mt-2 flex">
              <label>Simula pacchetti non validi (con la media ultimi 5):</label>
              <base-switch type="info" on-text="ON" off-text="OFF" v-model="tempInput.simulateInvalidPackets"></base-switch>
            </div>
            <!-- Source MinValue -->
            <div class="col-3">
              <ValidationProvider name="sourceMinValue" :rules="{ regex: /^\d*\.?\d*$/ }" v-slot="{ passed, failed }">
                <base-input
                  type="number"
                  v-model.number="tempInput.sourceMinValue"
                  label="Min input value"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                ></base-input>
              </ValidationProvider>
            </div>
            <!-- Source MaxValue -->
            <div class="col-3">
              <ValidationProvider name="sourceMaxValue" :rules="{ regex: /^\d*\.?\d*$/ }" v-slot="{ passed, failed }">
                <base-input
                  type="number"
                  v-model.number="tempInput.sourceMaxValue"
                  label="Max input value"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                ></base-input>
              </ValidationProvider>
            </div>
            <!-- Computed MinValue -->
            <div class="col-3">
              <ValidationProvider name="computedMinValue" :rules="{ regex: /^\d*\.?\d*$/ }" v-slot="{ passed, failed }">
                <base-input
                  type="number"
                  v-model.number="tempInput.computedMinValue"
                  label="Min post value"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                ></base-input>
              </ValidationProvider>
            </div>
            <!-- Computed MaxValue -->
            <div class="col-3">
              <ValidationProvider name="computedMaxValue" :rules="{ regex: /^\d*\.?\d*$/ }" v-slot="{ passed, failed }">
                <base-input
                  type="number"
                  v-model.number="tempInput.computedMaxValue"
                  label="Max post value"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                ></base-input>
              </ValidationProvider>
            </div>
          </div>
        </collapse-item>

        <collapse-item title="Pre-processing">
          <div class="row pt-3">
            <!-- Pre Offset -->
            <div class="col-3">
              <ValidationProvider name="preOffset" :rules="{ regex: /^\d*\.?\d*$/ }" v-slot="{ passed, failed }">
                <base-input type="number" v-model.number="tempInput.preOffset" label="Pre-offset" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"></base-input>
              </ValidationProvider>
            </div>
            <!-- Pre Factor  -->
            <div class="col-3">
              <ValidationProvider name="preFactor" :rules="{ regex: /^\d*\.?\d*$/ }" v-slot="{ passed, failed }">
                <base-input type="number" v-model.number="tempInput.preFactor" label="Pre-factor" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"></base-input>
              </ValidationProvider>
            </div>
            <!-- Post Offset -->
            <div class="col-3">
              <ValidationProvider name="postOffset" :rules="{ regex: /^\d*\.?\d*$/ }" v-slot="{ passed, failed }">
                <base-input type="number" v-model.number="tempInput.postOffset" label="Post-offset" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"></base-input>
              </ValidationProvider>
            </div>
            <!-- Post Factor -->
            <div class="col-3">
              <ValidationProvider name="postFactor" :rules="{ regex: /^\d*\.?\d*$/ }" v-slot="{ passed, failed }">
                <base-input type="number" v-model.number="tempInput.postFactor" label="Post-factor" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"></base-input>
              </ValidationProvider>
            </div>
          </div>
        </collapse-item>

        <collapse-item title="Conversione complessa" v-if="!nativeMode">
          <!-- Complex table modal -->
          <ComplexTable
            @close="showComplexTable = false"
            v-if="showComplexTable"
            :inputValues="tempInput.inputValues"
            :outputValues="tempInput.outputValues"
            :inputDataType="translateDataType(tempInput.source) + ' [' + getDefaultMesUnitsByDataType(tempInput.source) + ']'"
            :outputDataType="translateDataType(tempInput.destination) + ' [' + getDefaultMesUnitsByDataType(tempInput.destination) + ']'"
          />
          <div class="row pt-3">
            <!-- Complex -->
            <div class="col-4">
              <!-- <base-button size="sm" :wide="true" :block="true" :simple="false" type="success">Carica da .CSV</base-button> -->
              <!-- <input class="file-button" type="file" multiple="false" accept=".csv" @change="handleFileLoading" /> -->
              <csv-upload @change="handleFileLoading" />
            </div>
            <div class="col-4">
              <base-button size="sm" :wide="true" :block="true" :simple="false" type="info" :disabled="!isThereComplexData" @click="showComplexTable = !showComplexTable">{{
                showComplexTable ? "Chiudi tabella" : "Visualizza tabella"
              }}</base-button>
            </div>
            <div class="col-4">
              <base-button size="sm" :wide="true" :block="true" :simple="false" type="warning" @click="clearComplexData" :disabled="!isThereComplexData">Reset</base-button>
            </div>
            <!-- Info -->
            <div class="col-12">
              <p>
                <span class="strong"
                  >NB: il .CSV rispettare le seguenti unità di misurà:
                  {{ "[" + getDefaultMesUnitsByDataType(tempInput.source) + "] → [" + getDefaultMesUnitsByDataType(tempInput.destination) + "]" }}</span
                >
              </p>
            </div>
          </div>
        </collapse-item>

        <collapse-item title="Time reference" v-if="!nativeMode">
          <div class="row pt-3">
            <!-- prevTimeRef -->
            <div class="col-6">
              <ValidationProvider name="prevTimeRef" :rules="{ regex: /^-?\d*\.{0,1}\d+$/ }" v-slot="{ passed, failed }">
                <base-input
                  :disabled="tempInput.prevTimeDiv !== undefined"
                  type="number"
                  v-model.number="tempInput.prevTimeRef"
                  label="Prev Time Ref"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                ></base-input>
              </ValidationProvider>
            </div>
            <!-- prevTimeDiv -->
            <div class="col-6">
              <ValidationProvider name="prevTimeDiv" :rules="{ regex: /^-?\d*\.{0,1}\d+$/ }" v-slot="{ passed, failed }">
                <base-input
                  :disabled="tempInput.prevTimeRef !== undefined"
                  type="number"
                  v-model.number="tempInput.prevTimeDiv"
                  label="Prev Time Div"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                ></base-input>
              </ValidationProvider>
            </div>
            <!-- info -->
            <div class="col-6">
              <p>
                result = (rawValue * secondsDiff) / prevTimeRef <br /><br />es. <br />potenza -> energia: 3600<br />
                portata -> volume: 1
              </p>
            </div>
            <div class="col-6">
              <p>
                result = (rawValue / secondsDiff) * prevTimeDiv <br /><br />es. <br />energia -> potenza: 3600<br />
                volume -> portata: 1
              </p>
            </div>
          </div>
        </collapse-item>
      </collapse>

      <!-- Formula finale -->
      <div class="col-12 mt-2">
        <p class="m-0 p-0 text-center">
          Formula di conversione = <span class="strong">{{ conversionString + " [" + this.tempInput.mesUnit + "]" }}</span>
        </p>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import Vue from "vue";
import { Select, Option } from "element-ui";
import { BaseSwitch, Collapse, CollapseItem, CsvUpload } from "src/components/index";
import { getDefaultMesUnitsByDataType, getMesUnitsByDataType, availableDestinations } from "src/helpers/device";
import { translateDataType } from "src/helpers/translation";
import { convertInputCSVtoDoubleArray } from "src/helpers/CSV";
import ComplexTable from "./ComplexTable.vue";

export default {
  components: { CsvUpload, BaseSwitch, Collapse, CollapseItem, [Option.name]: Option, [Select.name]: Select, ComplexTable },
  props: {
    value: Object, // v-model
    nativeMode: Boolean, // 'true' se è un input nativo
    actualInputs: Array, // TUTTI gli inputs del sensore
    inputIndex: Number, // Indice dell'input iniettato (mi serve per distinguerlo dagli altri)
  },
  data() {
    return {
      showComplexTable: false,
      tempInput: null,
    };
  },
  methods: {
    getMesUnitsByDataType: getMesUnitsByDataType,
    getDefaultMesUnitsByDataType: getDefaultMesUnitsByDataType,
    translateDataType: translateDataType,
    removeDerivatedInput() {
      this.$emit("remove-me");
    },
    async handleFileLoading(event) {
      const complexData = await convertInputCSVtoDoubleArray(event);
      Vue.set(this.tempInput, "inputValues", complexData.inputValues);
      Vue.set(this.tempInput, "outputValues", complexData.outputValues);
    },
    clearComplexData() {
      Vue.set(this.tempInput, "inputValues", []);
      Vue.set(this.tempInput, "outputValues", []);
      delete this.tempInput.inputValues;
      delete this.tempInput.outputValues;
      this.showComplexTable = false;
    },
    validateInputSource(sourceType) {
      // Ritorna 'true' se la source scelta è valida (anche in base a tutti gli altri input)
      // ovvero se 'destination' è diverso da 'source' e se
      // esiste un input NATIVO che fornisce il dato di tipo 'source' oppure se
      // esiste un input DERIVATO che fornisce il dato di tipo 'source' oppure se
      return (
        (this.tempInput.destination !== sourceType && this.actualNativeInputs.some((inp) => inp.source === sourceType)) ||
        this.actualDerivatedInputs.some((inp) => inp.destination === sourceType)
      );
    },
    validateInputDestination(destinationType) {
      // Ritorna 'true' se la destination scelta è valida (anche in base a tutti gli altri input)
      // ovvero se 'source' è diverso da 'destination' e se
      // non esistono input NATIVI con la stessa 'destination' e se
      // non esistono input DERIVATI con la stessa 'destination' escluso me stesso
      return (
        this.tempInput.source !== destinationType &&
        !(
          this.actualNativeInputs.some((inp) => inp.source === destinationType) ||
          this.actualInputs.some((inp, ind) => inp.destination === destinationType && ind !== this.inputIndex)
        )
      );
    },
  },
  mounted() {
    this.tempInput = JSON.parse(JSON.stringify(this.value));
    /* if (!this.tempInput.mesUnit) {
      this.tempInput.mesUnit = getDefaultMesUnitsByDataType(this.tempInput.destination || this.tempInput.source);
    } */
  },
  computed: {
    availableDestinations() {
      return availableDestinations;
    },
    actualNativeInputs() {
      // Ritorna TUTTI gli inputs nativi del sensore
      return [...new Set((this.actualInputs || []).filter((inp) => !inp.destination))];
    },
    actualDerivatedInputs() {
      // Ritorna TUTTI gli inputs derivati del sensore (escluso l'attuale)
      return [...new Set((this.actualInputs || []).filter((inp, ind) => inp.destination !== undefined && ind !== this.inputIndex))];
    },
    availableSources() {
      // Ritorna tutte le possibili fonti di 'source'
      return [...new Set([...this.actualNativeInputs.map((inp) => inp.source), ...this.actualDerivatedInputs.map((inp) => inp.destination)])];
    },
    isThereComplexData() {
      // Ritorna 'true' se esiste qualche dato di conversione complessa
      return (this.tempInput.inputValues || []).length > 0;
    },
    conversionString() {
      let outString = "";
      const valueType = this.translateDataType(this.tempInput.destination || this.tempInput.source);
      // Pre-Offset
      if (this.tempInput.preOffset !== undefined) {
        outString += "(" + this.tempInput.preOffset + " - " + valueType + ")";
      } else {
        outString += "(" + valueType + ")";
      }
      // Pre-factor
      if (this.tempInput.preFactor !== undefined) {
        outString += " * " + this.tempInput.preFactor;
      }
      // Post-offset
      if (this.tempInput.postOffset !== undefined) {
        outString += " - " + this.tempInput.postOffset;
      }
      // Post-factor
      if (this.tempInput.postFactor !== undefined) {
        outString = "(" + outString + ")";
        outString += " * " + this.tempInput.postFactor;
      }
      if (this.tempInput.inputValues && this.tempInput.outputValues) {
        outString = "complex_conversion(" + outString + ")";
      }
      return outString;
    },
  },
  watch: {
    tempInput: {
      // Ad ogni cambiamento emetto l'evento per il v-model
      handler: function (newValue, oldValue) {
        Object.keys(this.tempInput).map((key) => {
          if (this.tempInput[key] === "" && key !== "mesUnit") {
            delete this.tempInput[key];
          }
        });
        this.tempInput["validationStatus"] = this.nativeMode || (this.validateInputSource(this.tempInput.source) && this.validateInputDestination(this.tempInput.destination));
        this.$emit("input", this.tempInput);
      },
      deep: true,
    },
    "tempInput.destination": function (newValue, oldValue) {
      if (newValue && oldValue && newValue !== oldValue) {
        // Carico l'unità di misura di default
        Vue.set(this.tempInput, "mesUnit", getDefaultMesUnitsByDataType(newValue));
        delete this.tempInput.prevTimeDiv;
        delete this.tempInput.prevTimeRef;
        // Carico le Time Reference di default
        if (this.tempInput.source === "power") {
          if (newValue === "energy") {
            delete this.tempInput.prevTimeDiv;
            Vue.set(this.tempInput, "prevTimeRef", 3600);
          }
        }
        if (this.tempInput.source === "flowrate") {
          if (newValue === "volume") {
            delete this.tempInput.prevTimeDiv;
            Vue.set(this.tempInput, "prevTimeRef", 1);
          }
        }
        if (this.tempInput.source === "energy") {
          if (newValue === "power") {
            delete this.tempInput.prevTimeRef;
            Vue.set(this.tempInput, "prevTimeDiv", 3600);
          }
        }
        if (this.tempInput.source === "volume") {
          if (newValue === "flowrate") {
            delete this.tempInput.prevTimeRef;
            Vue.set(this.tempInput, "prevTimeDiv", 1);
          }
        }
        // 2nd channel
        if (this.tempInput.source === "power2") {
          if (newValue === "energy2") {
            delete this.tempInput.prevTimeDiv;
            Vue.set(this.tempInput, "prevTimeRef", 3600);
          }
        }
        if (this.tempInput.source === "flowrate2") {
          if (newValue === "volume2") {
            delete this.tempInput.prevTimeDiv;
            Vue.set(this.tempInput, "prevTimeRef", 1);
          }
        }
        if (this.tempInput.source === "energy2") {
          if (newValue === "power2") {
            delete this.tempInput.prevTimeRef;
            Vue.set(this.tempInput, "prevTimeDiv", 3600);
          }
        }
        if (this.tempInput.source === "volume2") {
          if (newValue === "flowrate2") {
            delete this.tempInput.prevTimeRef;
            Vue.set(this.tempInput, "prevTimeDiv", 1);
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#main {
  //max-height: 80vh;
  //border-radius: 10px;
  overflow: scroll;
  //background-color: gray;
}
label {
  padding-right: 8px;
}
.warning {
  color: yellow;
}
.error {
  color: red;
}
.success {
  color: green;
}
</style>
