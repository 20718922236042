<template>
  <el-table :data="this.secondTableData" id="main">
    <el-table-column prop="name" min-width="140" :label="$t('deviceDetails.parameter')"> </el-table-column>
    <el-table-column :label="$t('deviceDetails.value')" min-width="120">
      <div slot-scope="{ row }">
        <div v-if="row.value === null">-</div>
        <base-progress v-else-if="row.type === 'progress'" :animated="true" :value="Number(row.value)" :label="''" value-position="right" />
        <div v-else-if="row.type === 'text'">{{ row.value }}</div>
        <i v-else-if="row.type === 'icon'" :class="[row.icon, 'tim-icons']"></i>
      </div>
    </el-table-column>
  </el-table>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import { BaseProgress } from "src/components";
import { mapGetters } from "vuex";
import { isOnline, getNormalizedBatteryLvl, getNormalizedQualitySignal } from "../../helpers/device";
export default {
  name: "device-details-table-gbs",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseProgress
  },
  data() {
    return {
      showSensorModal: false
    };
  },
  computed: {
    ...mapGetters({
      sensor: "dashboard/getSelectedDeviceObj"
    }),
    secondTableData() {
      return [
        {
          name: "ID",
          type: "text",
          value: this.sensor ? this.sensor.device : " - "
        },
        {
          name: this.$t("deviceDetails.name"),
          type: "text",
          value: this.sensor ? this.sensor.userConfig.name : " - "
        },
        {
          name: this.$t("deviceDetails.lastTransmission"),
          type: "text",
          value: this.sensor && this.sensor.lastTX ? new Date(this.sensor.lastTX).toLocaleString() : " - "
        },
        {
          name: this.$t("deviceDetails.status"),
          type: "text",
          value: this.sensor && this.sensor.lastTX ? (isOnline(this.sensor) ? "Online" : "Offline") : " - "
        },
        /* {
              id: 4,
              name: "Stato dispositivo",
              type: "icon",
              icon: "icon-check-2", // icon-alert-circle-exc
              color: "green",
              value: "Online"
            }, */
        {
          name: this.$t("deviceDetails.signalQuality"),
          type: "progress",
          value: this.sensor ? getNormalizedQualitySignal(this.sensor) : 0
        },
        {
          name: this.$t("deviceDetails.batteryLevel"),
          type: "progress",
          value: this.sensor && this.sensor.batteryLevel ? getNormalizedBatteryLvl(this.sensor) : null
        }
        /* {
              name: this.$t("deviceDetails.acquiredData"),
              type: "text",
              value: "distanza,portata"
            }, */
        /* {
          name: this.$t("deviceDetails.alarmStatus"),
          type: "icon",
          icon: "warning",
          value: "Ok"
        } */
      ];
    }
  }
};
</script>
<style scoped>
.table,
.el-table table {
  margin-bottom: 0;
}
#main {
  min-height: 400px;
}
</style>
