var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"card-body-classes":"table-full-width"}},[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.$t('logs.logList')))]),_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"},[_c('el-select',{staticClass:"select-primary mb-3 pagination-select",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1)],1),_c('el-table',{attrs:{"data":_vm.queriedData}},[_c('el-table-column',{attrs:{"sortable":"","label":_vm.$t('logs.time'),"min-width":"160","property":"time","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(new Date(row.time).toLocaleString()))])]}}])}),_c('el-table-column',{attrs:{"sortable":"","label":_vm.$t('logs.device'),"min-width":"160","property":"payload.device","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.payload.device))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('logs.dataType'),"min-width":"100","property":"payload.valueType","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.translateValueType(row.payload.valueType)))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('logs.alertType'),"min-width":"110","property":"payload.type","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.payload.type))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('logs.cleared'),"min-width":"100","property":"payload.triggered","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.payload.triggered)?_c('i',{staticClass:"tim-icons icon-alert-circle-exc",staticStyle:{"color":"red"}}):_c('i',{staticClass:"tim-icons icon-check-2",staticStyle:{"color":"green"}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('logs.value'),"min-width":"100","property":"payload.value","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.payload.value.toFixed(2)))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('logs.threshold'),"min-width":"110","property":"payload.threshold","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.payload.threshold.toFixed(2)))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('logs.sentTo'),"min-width":"290","property":"payload.sentTo","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.payload.sentTo),function(recipient){return _c('div',{key:recipient},[_vm._v(_vm._s(recipient))])})}}])})],1)],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(_vm._s(_vm.$t("table.showing"))+" "+_vm._s(_vm.from + 1)+" "+_vm._s(_vm.$t("table.to"))+" "+_vm._s(_vm.to)+" "+_vm._s(_vm.$t("table.of"))+" "+_vm._s(_vm.total)+" "+_vm._s(_vm.$t("table.entries")))])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }