<template>
  <div class="row" v-if="tempCounter">
    <!-- Error -->
    <div class="col-12" v-if="!validateCounterValueType(tempCounter.valueType)">
      <p class="error strong">Errore: Destinazione già presente su un altro contatore!</p>
    </div>
    <!-- Warning -->
    <div class="col-12" v-if="!checkForExistingSource(tempCounter.valueType)">
      <p class="warning strong">Warning: tipo di dato attualmente non fornito da nessun input!</p>
    </div>
    <collapse :multiple-active="false" :active-index="0" class="col-12">
      <collapse-item title="Generali">
        <div class="row">
          <!-- Abilitato -->
          <div class="col-12 flex" v-if="tempCounter.enabled !== undefined">
            <label>Contatore abilitato:</label>
            <base-switch type="info" on-text="ON" off-text="OFF" v-model="tempCounter.enabled"></base-switch>
          </div>
          <!-- Tipo di dato -->
          <div class="col-12">
            <label>Tipo di dato</label>
            <el-select class="select-info" v-model="tempCounter.valueType" placeholder="Value type" :class="[validateCounterValueType(tempCounter.valueType) ? '' : 'invalid']">
              <el-option v-for="newSource in countableSources" class="select-info" :value="newSource" :label="translateDataType(newSource)" :key="newSource"> </el-option>
            </el-select>
          </div>
          <!-- Ultima TX -->
          <div class="col-6">
            <label>Ultimo dato raccolto:</label>
            <p class="strong">{{ tempCounter.lastTX ? new Date(tempCounter.lastTX).toLocaleString() : "Never" }}</p>
          </div>
          <!-- Valore -->
          <div class="col-6">
            <label>Valore contatore:</label>
            <p class="strong">{{ tempCounter.value + " [" + getMesUnit(tempCounter.valueType) + "]" }}</p>
          </div>
          <!-- Azioni -->
          <div class="col-12 mt-2">
            <base-button class="auto-m" size="sm" :wide="false" :block="true" :simple="false" type="danger" @click="resetCounter">Resetta contatore selezionato</base-button>
          </div>
          <div class="col-12 mt-2">
            <base-button class="auto-m" size="sm" :wide="false" :block="true" :simple="true" type="warning" @click="removeCounter">Elimina contatore selezionato</base-button>
          </div>
        </div>
      </collapse-item>
    </collapse>
  </div>
</template>
<script>
import Vue from "vue";
import { Select, Option } from "element-ui";
import { BaseSwitch, Collapse, CollapseItem } from "src/components/index";
import { translateDataType } from "src/helpers/translation";
import { countableSources } from "src/helpers/device";

export default {
  components: { BaseSwitch, Collapse, CollapseItem, [Option.name]: Option, [Select.name]: Select },
  props: {
    value: Object, // v-model
    actualInputs: Array, // TUTTI gli inputs del sensore
    actualCounters: Array, // TUTTI i counters del sensore
    counterIndex: Number, // Indice del contatore iniettato (mi serve per distinguerlo dagli altri)
  },
  data() {
    return {
      tempCounter: null,
    };
  },
  methods: {
    removeCounter() {
      this.$emit("remove-me");
    },
    resetCounter() {
      Vue.set(this.tempCounter, "lastTX", null);
      Vue.set(this.tempCounter, "value", 0);
    },
    translateDataType: translateDataType,
    validateCounterValueType(valueType) {
      // Ritorna true se non esiste un ALTRO contatore con lo stesso 'valueType'
      return !(this.actualCounters || []).some((counter, index) => counter.valueType === valueType && index !== this.counterIndex);
    },
    checkForExistingSource(valueType) {
      // Ritorna true se c'è almeno un Input ATTIVO che fornisce il tipo di dato richiesto
      return this.actualInputs.some((input) => input.enabled && (input.destination === valueType || (!input.destination && input.source === valueType)));
    },
    getMesUnit(valueType) {
      let input = (this.actualInputs || []).find((inp) => !inp.destination && inp.source === valueType);
      if (!input) {
        input = (this.actualInputs || []).find((inp) => inp.destination && inp.destination === valueType);
      }
      if (input) {
        return input.mesUnit;
      } else {
        return "N.D.";
      }
    },
  },
  computed: {
    countableSources: () => countableSources,
  },
  mounted() {
    this.tempCounter = JSON.parse(JSON.stringify(this.value));
  },
  watch: {
    tempCounter: {
      // Ad ogni cambiamento emetto l'evento per il v-model
      handler: function (newValue, oldValue) {
        this.tempCounter["validationStatus"] = this.validateCounterValueType(this.tempCounter.valueType);
        this.$emit("input", this.tempCounter);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
label {
  padding-right: 8px;
}
.warning {
  color: yellow;
}
.error {
  color: red;
}
.success {
  color: green;
}
</style>
