<template>
  <!-- <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">Log</h2>
      <p class="text-center">
        In questa pagina puoi consultare i log.
      </p>
    </div> -->
  <div class="row mt-5">
    <div class="col-12">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">{{$t('logs.logList')}}</h4>
        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select class="select-primary mb-3 pagination-select" v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </div>
          <el-table :data="queriedData">
            <!-- Value Type -->
            <el-table-column sortable :label="$t('logs.time')" min-width="160" property="time" align="left">
              <template slot-scope="{ row }">
                <div>{{ new Date(row.time).toLocaleString() }}</div>
              </template>
            </el-table-column>
            <el-table-column sortable :label="$t('logs.device')" min-width="160" property="payload.device" align="left">
              <template slot-scope="{ row }">
                <div>{{ row.payload.device }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('logs.dataType')" min-width="100" property="payload.valueType" align="left">
              <template slot-scope="{ row }">
                <div>{{ translateValueType(row.payload.valueType) }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('logs.alertType')" min-width="110" property="payload.type" align="left">
              <template slot-scope="{ row }">
                <div>{{ row.payload.type }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('logs.cleared')" min-width="100" property="payload.triggered" align="left">
              <template slot-scope="{ row }">
                <i v-if="row.payload.triggered" class="tim-icons icon-alert-circle-exc" style="color:red;"></i>
                <i v-else class="tim-icons icon-check-2" style="color:green;"></i>
              </template>
            </el-table-column>
            <el-table-column :label="$t('logs.value')" min-width="100" property="payload.value" align="left">
              <template slot-scope="{ row }">
                <div>{{ row.payload.value.toFixed(2) }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('logs.threshold')" min-width="110" property="payload.threshold" align="left">
              <template slot-scope="{ row }">
                <div>{{ row.payload.threshold.toFixed(2) }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('logs.sentTo')" min-width="290" property="payload.sentTo" align="left">
              <template slot-scope="{ row }">
                <div v-for="recipient in row.payload.sentTo" :key="recipient">{{ recipient }}</div>
                <!-- <base-dropdown menu-on-right="" tag="div" title-classes="btn btn-link btn-icon" :class="{ 'float-left': $rtl.isRTL }">
                    <i slot="title" class="tim-icons icon-settings-gear-63"></i>
                    <a v-for="recipient in row.payload.sentTo" :key="recipient" class="dropdown-item">{{ recipient }}</a>
                  </base-dropdown> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">{{ $t("table.showing") }} {{ from + 1 }} {{ $t("table.to") }} {{ to }} {{ $t("table.of") }} {{ total }} {{ $t("table.entries") }}</p>
          </div>
          <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total"> </base-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { getLogs } from "../../services/api";
import { BasePagination } from "src/components";
import { ddmmyyyyFormat } from "../../helpers/dates";
import { translateDataType } from "../../helpers/translation";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.logsData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0 ? this.searchedData.length : this.logsData.length;
    }
  },
  data() {
    return {
      currentTab: "alert",
      logsData: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["payload.device"],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    formatTimeField(time) {
      return ddmmyyyyFormat(time);
    },
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)"
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    translateValueType(dataType) {
      return translateDataType(dataType);
    },
    deleteRow(row) {
      let indexToDelete = this.logsData.findIndex(tableRow => tableRow.id === row.id);
      if (indexToDelete >= 0) {
        this.logsData.splice(indexToDelete, 1);
      }
    }
  },
  async mounted() {
    this.startLoading();
    const logs = await getLogs(undefined, "threshold-alert", undefined);
    this.logsData = logs;
    this.stopLoading();
    /* } */
  },
  watch: {
    searchQuery(value) {
      let result = this.logsData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }
};
</script>
<style scoped>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content {
  font-size: inherit !important;
}
a {
  color: white;
}
</style>
