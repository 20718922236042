<template>
  <div class="row h-100">
    <div class="col-12 pb-3">
      <card card-body-classes="table-full-width" class="h-100">
        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(submit)" class="h-100" autocomplete="do-not-autofill">
            <card footer-classes="text-right" class="h-100">
              <div slot="header"><h4 class="card-title">Dettagli cliente</h4></div>
              <div class="row">
                <!-- Nome -->
                <ValidationProvider class="col-md-6" name="fn" rules="required" v-slot="{ passed, failed, errors }">
                  <base-input
                    required
                    v-model="userObj.firstName"
                    @input="unsavedChanges = true"
                    type="text"
                    label="Nome"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                  </base-input>
                </ValidationProvider>
                <!-- Cognome -->
                <ValidationProvider class="col-md-6" name="ln" rules="required" v-slot="{ passed, failed, errors }">
                  <base-input
                    required
                    v-model="userObj.lastName"
                    @input="unsavedChanges = true"
                    type="text"
                    label="Cognome"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                  </base-input>
                </ValidationProvider>
                <!-- Password -->
                <ValidationProvider
                  class="col-md-6"
                  name="password"
                  :rules="{
                    confirmed: 'confirmation',
                    regex: /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/,
                  }"
                  v-slot="{ passed, failed, errors }"
                  :custom-messages="{ regex: 'Minimo 7 caratteri, almeno un numero ed un carattere speciale.', confirmed: $t('validation.pswEquals') }"
                >
                  <base-input
                    v-model="userObj.password"
                    @input="unsavedChanges = true"
                    type="password"
                    label="Cambio Password"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                  </base-input>
                </ValidationProvider>
                <!-- Conferma Password -->
                <ValidationProvider class="col-md-6" name="confirm" vid="confirmation" v-slot="{ passed, failed, errors }">
                  <base-input
                    v-model="pswConfirmation"
                    @input="unsavedChanges = true"
                    type="password"
                    label="Conferma Password"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                  </base-input>
                </ValidationProvider>
                <!-- Email -->
                <ValidationProvider class="col-md-6" name="email" rules="email|required" v-slot="{ passed, failed, errors }">
                  <base-input
                    required
                    v-model="userObj.email"
                    @input="unsavedChanges = true"
                    type="email"
                    label="Email"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  ></base-input>
                </ValidationProvider>
                <!-- Company -->
                <ValidationProvider class="col-md-6" name="company" v-slot="{ passed, failed, errors }">
                  <base-input
                    v-model="userObj.company"
                    @input="unsavedChanges = true"
                    type="text"
                    label="Company"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                  </base-input>
                </ValidationProvider>
                <!-- Indirizzo -->
                <ValidationProvider class="col-md-8" name="address" v-slot="{ passed, failed, errors }">
                  <base-input
                    v-model="userObj.address"
                    @input="unsavedChanges = true"
                    type="text"
                    label="Indirizzo"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  ></base-input>
                </ValidationProvider>
                <!-- VAT -->
                <ValidationProvider class="col-md-4" name="VAT" v-slot="{ passed, failed, errors }">
                  <base-input
                    v-model="userObj.VAT"
                    @input="unsavedChanges = true"
                    type="text"
                    label="VAT"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                  </base-input>
                </ValidationProvider>

                <div class="col-12 category form-category">* Required fields</div>
              </div>
              <template slot="footer">
                <!-- <base-checkbox v-model="subscribe" class="pull-left" name="subscribe"> Accept terms & conditions </base-checkbox> -->
                <!-- <base-button native-type="submit" type="primary">New</base-button> -->
                <div class="col-md-12 footer-col">
                  <div class="row">
                    <div class="col-md-6" align="center">
                      <base-button type="default" class="btn-full" @click="undo"> {{ $t("deviceSettings.undo") }} </base-button>
                    </div>
                    <div class="col-md-6" align="center">
                      <base-button type="warning" class="btn-full" @click="save" :disabled="invalid || !unsavedChanges">
                        <i class="tim-icons icon-settings"></i> {{ $t("deviceSettings.save") }}
                      </base-button>
                    </div>
                  </div>
                </div>
              </template>
            </card>
          </form>
        </ValidationObserver>
      </card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      userObj: {},
      pswConfirmation: null,
      unsavedChanges: false,
    };
  },
  methods: {
    ...mapActions({ patchExistingUser: "admin/patchExistingUser" }),

    submit() {
      console.log("Form has been submitted!");
    },
    undo() {
      this.userObj = JSON.parse(JSON.stringify(this.user));
    },
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    async save() {
      let patchObj = {
        email: this.userObj.email,
        firstName: this.userObj.firstName,
        lastName: this.userObj.lastName,
        phone: this.userObj.phone,
        address: this.userObj.address,
        company: this.userObj.company,
        VAT: this.userObj.VAT,
        password: this.userObj.password,
      };
      if (!patchObj.password) {
        delete patchObj.password;
      }
      Object.keys(patchObj).forEach((key) => {
        if (patchObj[key] === undefined) {
          delete patchObj[key];
        }
      });
      this.startLoading();
      await this.patchExistingUser({ _id: this.userObj._id, obj: patchObj });
      this.stopLoading();
    },
  },
  computed: {
    ...mapGetters({ user: "admin/getSelectedUserObj" }),
  },
  watch: {
    user(newValue) {
      this.userObj = JSON.parse(JSON.stringify(newValue));
      this.userObj.password = undefined;
      this.pswConfirmation = null;
    },
  },
  mounted() {
    this.userObj.password = undefined;
  },
};
</script>
