<template>
  <card class="card-stats" :show-footer-line="true">
    <!-- Valore -->
    <div class="row" v-if="currentView === 'value' && selectedType">
      <div class="col-12" v-if="$slots.content || title">
        <div class="numbers">
          <slot>
            <div class="flex">
              <h3 v-if="title" class="card-title">{{ title + ": " + value + " " + mesUnit }}</h3>
              <i :style="{ color: status ? 'var(--success)' : 'var(--warning)' }" :class="[status ? 'icon-check-2' : 'icon-alert-circle-exc', 'tim-icons']"></i>
              <i style="color: grey; cursor: pointer" class="icon-settings-gear-63 tim-icons" @click="showSettingsTab"></i>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <!-- Settings -->
    <div class="row" v-if="currentView === 'settings'">
      <div class="col-12" v-if="$slots.content || title">
        <div class="numbers">
          <slot>
            <div class="flex">
              <p class="setting-title">Tipo di dato visualizzato:</p>
              <el-select class="select-info" size="m" :placeholder="'sss'" v-model="selectedType">
                <el-option class="select-option" v-for="option in availableTypes" :value="option" :label="translateValueType(option)" :key="option"> </el-option>
              </el-select>
              <i style="color: var(--success); cursor: pointer" class="icon-check-2 tim-icons" @click="saveSettings"></i>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <div class="stats" slot="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </card>
</template>
<script>
import Card from "../components/Cards/Card.vue";
import { Select, Option } from "element-ui";
import { translateDataType } from "../helpers/translation";

export default {
  name: "stats-card",
  components: {
    Card,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props: {
    icon: String,
    values: Array, // Array di oggetti: [{value: 3, type: "power", mesUnit: "kW"}, {...}, ...]
  },
  data() {
    return {
      selectedType: "", // Tipo attualmente selezionato (per adesso 'power' | 'flowrate')
      currentView: "value", // value | settings
    };
  },
  computed: {
    // Ritorna il titolo da dare alla sezione di visualizzazione
    title() {
      if (this.selectedType === "power") {
        return "Potenza attuale";
      } else if (this.selectedType === "flowrate") {
        return "Portata attuale";
      } else if (this.selectedType === "power2") {
        return "Potenza attuale (2)";
      } else if (this.selectedType === "flowrate2") {
        return "Portata attuale (2)";
      } else {
        return "";
      }
    },
    // Ritorna l'oggetto relativo al tipo selezionato
    valueObj() {
      return this.values.find((vl) => vl.type === this.selectedType) || {};
    },
    // Ritorna true se il tipo selezionato è maggiore di zero
    status() {
      return this.valueObj.value !== undefined && this.valueObj.value !== null && this.valueObj.value > 0;
    },
    // Ritorna il valore numero del tipo selezionato
    value() {
      return this.valueObj.value;
    },
    // Ritorna l'unità di misura relativa al tipo selezionato
    mesUnit() {
      return this.valueObj.mesUnit;
    },
    // Ritorna la lista di tipi passati al componente
    availableTypes() {
      const valuesObjs = this.values && this.values.filter((vl) => vl.value !== undefined && vl.value !== null);
      return valuesObjs.map((vlo) => vlo.type);
    },
  },
  methods: {
    // Ritorna il primo tipo di dato disponibile
    getFirstAvailableDataType() {
      const valueObjFound = this.values && this.values.find((vl) => vl.value !== undefined && vl.value !== null);
      return valueObjFound ? valueObjFound.type : null;
    },
    // Setta la vista 'settings'
    showSettingsTab() {
      this.currentView = "settings";
    },
    // Salva il tipo desiderato nel localStorage e setta la vista 'value'
    saveSettings() {
      localStorage.setItem("selectedHighlightDataType", this.selectedType);
      this.currentView = "value";
    },
    translateValueType(valueType) {
      return translateDataType(valueType);
    },
  },
  mounted() {
    // All'avvio controllo se ho un tipo salvato nel localStorage, se sì lo imposto, sennò scelgo il primo
    let actualType = localStorage.getItem("selectedHighlightDataType");
    if (actualType) {
      this.selectedType = actualType;
    } else {
      actualType = this.getFirstAvailableDataType();
      localStorage.setItem("selectedHighlightDataType", actualType);
      this.selectedType = actualType;
    }
  },
};
</script>
<style scoped>
.card-title {
  margin: 0 !important;
}
h3 {
  font-size: 1.4em !important;
  text-align: left;
  padding-left: 20px;
  padding: 6px 0 6px 20px;
}
p {
  text-align: left;
}
.flex {
  display: flex;
  margin: auto;
}
i {
  margin: auto;
  font-size: 20px;
  margin-right: 4%;
}
.select-info {
  margin-left: 4%;
  max-width: 40%;
}
.select-option {
  font-size: 19px;
}
.setting-title {
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
