<template>
  <card>
    <template slot="header">
      <div class="row">
        <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
          <h5 class="card-category">FTP-CAMERA</h5>
          <h2 class="card-title">{{ cameraID }}</h2>
          <p class="card-desc" v-if="lastPollingDate">{{ $t("camera.lastUpdate") + " : " + lastPollingDate.toLocaleString() }}</p>
        </div>
        <div class="col-sm-6 d-flex d-sm-block">
          <div class="btn-group btn-group-toggle" :class="isRTL ? 'float-left' : 'float-right'" data-toggle="buttons">
            <base-button @click="downloadData()" size="sm" type="info"> <i class="tim-icons icon-cloud-download-93"></i></base-button>
          </div>
        </div>
      </div>
    </template>
    <div id="camera-image">
      <img v-if="cameraImage && cameraImage !== 'null'" :src="'data:image/jpeg;base64, ' + cameraImage" alt="Camera Image" />
      <div class="mex-div" v-else><h2>Loading...</h2></div>
    </div>
  </card>
</template>
<script>
import { getFtpImage } from "../../services/api";

export default {
  name: "camera-gbs",
  props: {
    cameraID: String
  },
  data() {
    return {
      cameraImage: null,
      pollingInterval: null,
      pollingDeltaSeconds: 120, // Secondi polling,
      lastPollingDate: null
    };
  },
  computed: {
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  methods: {
    async startPolling() {
      this.cameraImage = await getFtpImage(this.cameraID);
      this.lastPollingDate = new Date();
      this.pollingInterval = setInterval(async () => {
        console.log("FTP-Camera Polling! (" + this.pollingDeltaSeconds + " seconds interval).");
        this.cameraImage = await getFtpImage(this.cameraID);
        this.lastPollingDate = new Date();
      }, this.pollingDeltaSeconds * 1000);
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
    },
    downloadData() {
      var a = document.createElement("a"); //Create <a>
      a.href = "data:image/png;base64," + this.cameraImage; //Image Base64 Goes here
      a.download = "CameraImage.png"; //File name Here
      a.click(); //Downloaded file
    }
  },
  async mounted() {
    this.$emit("loading");
    await this.startPolling();
    this.$emit("loaded");
  },
  watch: {
    // Quando cambio camera voglio re-inizializzare il tutto
    cameraID(newValue, oldValue) {
      if (newValue && oldValue) {
        this.stopPolling();
        this.cameraImage = null;
        this.startPolling();
      }
    }
  },
  beforeDestroy() {
    this.stopPolling();
  }
};
</script>
<style scoped>
#camera-image {
  min-height: 600px;
}
#camera-image > img {
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}
.mex-div {
  height: 100%;
}
.mex-div > * {
  margin: auto;
}
.card-desc {
  font-size: 0.9em;
  /* font-style: italic; */
}
</style>
