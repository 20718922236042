var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-12 pb-3"},[_c('div',{staticClass:"extended-forms",attrs:{"id":"wrap"}},[_c('el-dialog',{staticClass:"min-dialog",attrs:{"visible":_vm.showSensorSettingsModal},on:{"update:visible":function($event){_vm.showSensorSettingsModal=$event}}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[(_vm.showSensorSettingsModal && _vm.sensorObj)?_c('SensorSettingsModal',{attrs:{"sensor":_vm.sensorObj},on:{"close":function($event){_vm.showSensorSettingsModal = false},"save":_vm.saveSensorSettings}}):_vm._e()],1),_c('span'),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"})]),(_vm.sensorObj)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('form',[_c('card',[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Riepilogo Sensore")]),_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.sensorObj.device))]),_c('div',[_c('h4',{style:({ color: _vm.getOnline(_vm.sensorObj) ? 'green' : 'red' })},[_vm._v(" Ultima trasmissione: "+_vm._s(_vm.sensorObj.lastTX ? new Date(_vm.sensorObj.lastTX).toLocaleString() : "Never")+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('base-progress',{attrs:{"label":"Batteria","value-position":"right","value":_vm.getBL(_vm.sensorObj)}})],1),_c('div',{staticClass:"col-md-6"},[_c('base-progress',{attrs:{"label":"Segnale","value-position":"right","value":_vm.getSQ(_vm.sensorObj) || 'N.D.'}})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"Nome custom","error":errors[0]},on:{"input":function($event){_vm.unsavedChanges = true}},model:{value:(_vm.sensorObj.userConfig.name),callback:function ($$v) {_vm.$set(_vm.sensorObj.userConfig, "name", $$v)},expression:"sensorObj.userConfig.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"number","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"Keep alive (sec.)","error":errors[0]},on:{"input":function($event){_vm.unsavedChanges = true}},model:{value:(_vm.sensorObj.keepAlive),callback:function ($$v) {_vm.$set(_vm.sensorObj, "keepAlive", $$v)},expression:"sensorObj.keepAlive"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('base-button',{attrs:{"wide":true,"block":true,"simple":false,"type":"info"},on:{"click":function($event){return _vm.openInputModal(undefined)}}},[_vm._v("Impostazioni vanzate")])],1)])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 flex"},[_c('base-button',{staticClass:"auto-m",attrs:{"type":"success","disabled":!_vm.unsavedChanges || invalid},on:{"click":_vm.saveQuick}},[_vm._v("Salva ")])],1),_c('div',{staticClass:"col-6 flex"},[_c('base-button',{staticClass:"auto-m",attrs:{"type":"warning","disabled":!dirty},on:{"click":_vm.undo}},[_vm._v("Annulla")])],1)])])],2)],1)]}}],null,false,1479033713)}):_c('div',{staticClass:"empty-module"},[_c('h3',[_vm._v("Aggiungi/seleziona un sensore...")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }