<template>
  <div class="row">
    <div class="col-lg-7 col-md-7">
      <Users />
    </div>
    <div class="col-lg-5 col-md-5">
      <UserDetails />
    </div>
    <div class="col-lg-7 col-md-7">
      <Groups />
      <Sensors />
    </div>
    <div class="col-lg-5 col-md-5">
      <SensorDetails />
    </div>
    <div class="col-lg-7 col-md-7">
      <Outputs />
    </div>
    <div class="col-lg-5 col-md-5">
      <OutputDetails />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Option, Dialog } from "element-ui";
import Users from "./Users/Users.vue";
import UserDetails from "./Users/UserDetails.vue";
import Groups from "./Groups/Groups.vue";
import Sensors from "./Sensors/Sensors.vue";
import SensorDetails from "./Sensors/SensorDetails.vue";
import Outputs from "./Outputs/Outputs.vue";
import OutputDetails from "./Outputs/OutputDetails.vue";

export default {
  components: {
    Users,
    UserDetails,
    Groups,
    Outputs,
    Sensors,
    SensorDetails,
    OutputDetails,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
  },
  computed: {
    ...mapGetters({ getUserList: "admin/getUserList" }),
  },
  methods: {
    ...mapActions({ fetchUserList: "admin/fetchUserList", fetchUserInfo: "user/fetchUserInfo" }),
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
  },
  async mounted() {
    this.startLoading();
    await this.fetchUserInfo();
    await this.fetchUserList();
    this.stopLoading();
  },
  watch: {},
  beforeDestroy() {},
};
</script>
<style scoped>
.flat-card > .card-body {
  padding: 0;
}
.flat-card {
  height: calc(100% - 20px);
  margin-bottom: 20px;
}
.select-primary {
  width: 100%;
}
</style>
