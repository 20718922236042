<template>
  <div id="satelliteMap" class="map map-big" ref="map"></div>
</template>
<script>
import { API_KEY } from "./API_KEY";
import { Loader, LoaderOptions } from "google-maps";

const ONLINE_ICON = require("../../../public/icons/online_radio.svg");
const OFFLINE_ICON = require("../../../public/icons/offline_radio.svg");
const ALERT_ICON = require("../../../public/icons/alert_radio.svg");
const SEL_ONLINE_ICON = require("../../../public/icons/sel_online_radio.svg");
const SEL_OFFLINE_ICON = require("../../../public/icons/sel_offline_radio.svg");
const SEL_ALERT_ICON = require("../../../public/icons/sel_alert_radio.svg");
const CENTER_CONTROL = require("../../../public/icons/center-focus.svg");

const ONLINE_TVCC = require("../../../public/icons/online_tvcc.svg");
const SELECTED_TVCC = require("../../../public/icons/selected_tvcc.svg");

const loader = new Loader(API_KEY);

export default {
  name: "maps-gbs",
  props: {
    enabled: Boolean,
    center: { lat: Number, lng: Number },
    zoom: Number,
    mapType: String,
    disableUI: Boolean,
    devices: Array,
    selectedDeviceID: String,
    editingDeviceID: String
  },
  data() {
    return {
      chicago: { lat: 41.85, lng: -87.65 },
      map: null,
      markers: [],
      devicesIDS: [],
      infoWindows: []
    };
  },
  methods: {
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)"
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    // Attiva/disattiva animazione
    toggleBounce(marker) {
      if (marker.getAnimation() !== null) {
        marker.setAnimation(null);
      } else {
        marker.setAnimation(window.google.maps.Animation.BOUNCE);
      }
    },
    // Trigger sposta sensore
    moveDevice(deviceID) {
      const index = this.devicesIDS.indexOf(deviceID);
      if (index != -1) {
        this.markers[index].setDraggable(true);
        this.infoWindows[index].close();
        this.toggleBounce(this.markers[index]);
        window.google.maps.event.addListener(this.markers[index], "dragstart", function(/* pt */) {
          //infoWindows[index].close();
        });
        window.google.maps.event.addListener(this.markers[index], "dragend", pt => {
          this.infoWindows[index].setPosition(pt.latLng);
          //infoWindows[index].open(map.value);
          if (pt && !isNaN(pt.latLng.lat()) && !isNaN(pt.latLng.lng())) {
            this.$emit("update-sensor-position", [pt.latLng.lat(), pt.latLng.lng()]);
          }
        });
      }
    },
    // Stop sposta sensore
    stopMoveDevice(deviceID) {
      const index = this.devicesIDS.indexOf(deviceID);
      this.markers[index].setDraggable(false);
      window.google.maps.event.clearInstanceListeners(this.markers[index]);
      this.toggleBounce(this.markers[index]);
    },
    cleanMap() {
      if (this.map) {
        console.log("Cleaning Map");
        this.markers.forEach(mk => mk.setMap(null));
        this.infoWindows.forEach(iw => {
          window.google.maps.event.clearInstanceListeners(iw); // just in case handlers continue to stick around
          iw.setMap(null);
        });
        this.infoWindows = [];
        this.markers = [];
        this.devicesIDS = [];
        this.map.data.forEach(feature => {
          this.map.data.remove(feature);
        });
      }
    },
    removeGoogleMapScript() {
      console.debug("removing google script...");
      const keywords = ["maps.googleapis", "key=AIzaSyCtKrxXqq0_ASat6QpQgGWaJOxZm-EltBQ"];

      //Remove google from BOM (window object)
      window.google = undefined;

      //Remove google map scripts from DOM
      const scripts = document.head.getElementsByTagName("script");
      for (let i = scripts.length - 1; i >= 0; i--) {
        const scriptSource = scripts[i].getAttribute("src");
        if (scriptSource != null) {
          if (keywords.filter(item => scriptSource.includes(item)).length) {
            scripts[i].remove();
          }
        }
      }
    },

    reDrawMarkers(animation) {
      if (!this.map) return;
      this.cleanMap();
      const bounds = new window.google.maps.LatLngBounds();
      this.map.data.addGeoJson(this.geoJSON);
      // Nascondo data layer per mostrare i miei markers
      this.map.data.setStyle(function() {
        return {
          visible: false
        };
      });
      // Main routine
      this.geoJSON.features.forEach(feature => {
        const latLng = new window.google.maps.LatLng(parseFloat(feature.geometry.coordinates[0]), parseFloat(feature.geometry.coordinates[1]));
        // Pusho marker
        const marker = new window.google.maps.Marker({
          markerID: feature.properties.id,
          position: latLng,
          map: this.map,
          animation: animation ? window.google.maps.Animation.DROP : null,
          icon: this.getMarkerBySensorObj(this.devices.find(sn => sn._id === feature.properties.id)) //selectedSensor.value === feature.properties.device ? SEL_SENSOR_ICON: feature.properties.icon,
        });
        //Push array ID
        this.devicesIDS.push(feature.properties.id);
        this.markers.push(marker);
        marker.addListener("click", () => {
          // Seleziono device, setto le altre icone standard e questa blu
          this.$emit("select-devices", [feature.properties.id]);
        });

        // Pusho infowindow
        const infowindow = new window.google.maps.InfoWindow({ disableAutoPan: true });
        const myHTML = feature.properties.title;
        infowindow.setContent("<div class='info-window' style='width:150px; text-align: center; padding:0; margin:0'>" + "<h6 class='no-h-head'>" + myHTML + "</h6>" + "</div>");
        infowindow.setPosition(latLng);
        infowindow.setOptions({ pixelOffset: new window.google.maps.Size(0, -40), disableAutoPan: true });
        infowindow.open(this.map);
        this.infoWindows.push(infowindow);
        // Fitto mappa a sensori
        bounds.extend(latLng);
      });
      setTimeout(() => {
        this.map.fitBounds(bounds);
      }, 80);
    },
    CenterControl(controlDiv, map) {
      // Set CSS for the control border.
      const controlUI = document.createElement("div");
      controlUI.style.width = "30px";
      controlUI.style.height = "30px";
      controlUI.style.backgroundColor = "#fff";
      controlUI.style.border = "2px solid #fff";
      controlUI.style.borderRadius = "3px";
      controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlUI.style.cursor = "pointer";
      controlUI.style.marginRight = "6px";
      controlUI.style.display = "flex";
      controlUI.title = "Click to recenter the map";
      controlDiv.appendChild(controlUI);
      // Set CSS for the control interior.
      const controlImg = document.createElement("img");
      controlImg.src = CENTER_CONTROL;
      controlImg.style.alt = "Center";
      controlImg.style.width = "70%";
      controlImg.style.height = "70%";
      controlImg.style.margin = "auto";
      controlUI.appendChild(controlImg);
      // Setup the click event listeners: simply set the map to Chicago.
      controlUI.addEventListener("click", () => {
        const bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < this.markers.length; i++) {
          bounds.extend(this.markers[i].position);
        }
        setTimeout(() => {
          this.map.fitBounds(bounds);
        }, 20);
      });
    },
    /* renderMap(google) {
      this.cleanMap();
      // Satellite Map Begin
      const mapOptions = {
        zoom: this.zoom || 16,
        disableDefaultUI: this.disableUI || false,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        center: this.center || { lat: 43.83849, lng: 10.507736 },
        controlSize: 29,
        gestureHandling: "cooperative",
        mapTypeId: this.mapType || window.google.maps.MapTypeId.SATELLITE,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: true
      };

      this.map = new window.google.maps.Map(document.getElementById("satelliteMap"), mapOptions);
      const centerControlDiv = document.createElement("div");
      this.CenterControl(centerControlDiv, this.map);
      this.map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(centerControlDiv);
    }, */
    changeSelectedDevice() {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setIcon(this.getMarkerBySensorObj(this.devices.find(sn => sn._id === this.devicesIDS[i])));
      }
    },
    isSensorOnline(sensor) {
      const lastTX = sensor.lastTX;
      //const MIN_30 = 30 * 60 * 1000;
      const MIN_62 = 62 * 60 * 1000;
      const timeDiffMS = new Date() - new Date(lastTX);
      return (lastTX && timeDiffMS < MIN_62) || sensor.vendor === "generic";
    },
    getStatusBySensorObj(sensorObj) {
      let status;
      const isOnline = this.isSensorOnline(sensorObj);

      if (isOnline) {
        status = "online";
      } else {
        status = "offline";
      }
      if (sensorObj.userConfig.alerts.some(al => al.status === true)) {
        status = "alert";
      }
      return status;
    },
    getMarkerBySensorObj(sensorObj) {
      const icon = { scaledSize: new window.google.maps.Size(40, 48) };
      const isSelected = sensorObj && this.selectedDeviceID === sensorObj._id;
      const isOnline = sensorObj && this.isSensorOnline(sensorObj);

      if (sensorObj.staticType && sensorObj.staticType === "FTP-CAMERA") {
        if (isSelected) {
          icon.url = SELECTED_TVCC;
        } else {
          icon.url = ONLINE_TVCC;
        }
        return icon;
      }

      if (isOnline) {
        icon.url = ONLINE_ICON;
        if (isSelected) {
          icon.url = SEL_ONLINE_ICON;
        }
      } else {
        icon.url = OFFLINE_ICON;
        if (isSelected) {
          icon.url = SEL_OFFLINE_ICON;
        }
      }
      if (sensorObj && sensorObj.userConfig.alerts.some(al => al.status === true)) {
        if (isSelected) {
          icon.url = SEL_ALERT_ICON;
        } else {
          icon.url = ALERT_ICON;
        }
      }
      return icon;
    }
  },
  computed: {
    geoJSON() {
      return {
        type: "FeatureCollection",
        features: this.devices
          .filter(sn => sn.loc && sn.loc.length > 0 && sn.loc.some(loc => loc.type === "geo"))
          .map(sn => {
            const loc = sn.loc.find(loc => loc.type === "geo");
            return {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [loc.coordinates[0], loc.coordinates[1]]
              },
              properties: {
                title: sn.userConfig.name || sn.device.substring(sn.device.length - 4),
                device: sn.device,
                id: sn._id,
                status: this.getStatusBySensorObj(sn),
                selected: this.selectedDeviceID === sn._id
              }
            };
          })
      };
    }
  },
  mounted() {
    //this.startLoading();
    //loader.load().then(google => this.renderMap(google));
    this.removeGoogleMapScript();
    const googleMapScript = document.createElement("script");
    googleMapScript.setAttribute("src", `https://maps.googleapis.com/maps/api/js?key=AIzaSyCtKrxXqq0_ASat6QpQgGWaJOxZm-EltBQ&callback=initMap`);
    googleMapScript.setAttribute("defer", "");
    googleMapScript.setAttribute("async", "");
    document.head.appendChild(googleMapScript);

    // MAPPA INIZIALIZZATA
    window.initMap = () => {
      if (!this.$refs.map) return;
      this.map = new window.google.maps.Map(this.$refs.map, {
        mapTypeId: this.mapType || window.google.maps.MapTypeId.SATELLITE,
        zoom: this.zoom || 16,
        disableDefaultUI: this.disableUI || false,
        center: this.center || { lat: 43.83849, lng: 10.507736 },
        controlSize: 29,
        gestureHandling: "cooperative"
      });
      const centerControlDiv = document.createElement("div");
      this.CenterControl(centerControlDiv, this.map);
      this.map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(centerControlDiv);
      this.reDrawMarkers();
      //this.stopLoading();
    };
  },

  watch: {
    // TODO: check
    geoJSON(newValue, oldValue) {
      const newFt = newValue ? newValue.features : null;
      const oldFt = oldValue ? oldValue.features : null;
      // Re-draw the markres only if the sensors or the characteristics (status and name) of at least one sensor change.
      for (let i = 0; i < newFt.length; i++) {
        const oldFeat = oldFt.find(ft => ft.properties.device === newFt[i].properties.device);
        if (!oldFeat || oldFeat.properties.status != newFt[i].properties.status || oldFeat.properties.title != newFt[i].properties.title) {
          this.reDrawMarkers();
          break;
        }
      }
    },
    // TODO: check
    devices(/* newValue, oldValue */) {
      if (this.enabled) {
        this.reDrawMarkers();
      }
    },
    enabled(newValue) {
      if (newValue) {
        //this.reDrawMarkers();
        setTimeout(() => {}, 1000);
      }
    },
    selectedDeviceID(newSelectedDeviceID) {
      if (this.enabled) {
        this.changeSelectedDevice(newSelectedDeviceID);
      }
    },
    editingDeviceID(newValue, oldValue) {
      if (this.enabled) {
        if (newValue != null) {
          this.moveDevice(newValue);
        } else {
          this.stopMoveDevice(oldValue);
          this.reDrawMarkers();
        }
      }
    }
  },
  beforeDestroy() {
    this.cleanMap();
  }
};
</script>
<style lang="css" scoped>
.map {
  height: 300px;
  width: 100%;
}
* {
  color: black !important;
}

.info-window {
  background-color: black !important;
}
.gm-style-iw {
  background: #00a3ff !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.gm-style div div div div div div div div div {
  background-color: #00a3ff !important;
}
</style>
