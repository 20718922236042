<template>
  <div class="row" v-if="getSelectedDeviceObj">
    <!-- <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">Dashboard</h2>
      <p class="text-center">
        With a selection of custom components & and Element UI components, you can built beautiful data tables. For more info check
      </p>
    </div> -->
    <!-- Device settings modal -->
    <el-dialog :visible.sync="showDeviceSettings" class="min-dialog">
      <span slot="title">
        <DeviceSettings @close="showDeviceSettings = false" v-if="showDeviceSettings" />
      </span>
      <span></span>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>

    <div class="col-lg-8 col-md-12">
      <card class="card" :header-classes="{ 'text-right': isRTL }" v-if="getUserObj">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm12">
            <div class="row">
              <label class="col-lg-6 col-md-12 col-form-label">{{ $t("dashboard.group") }}:</label>
              <!-- Se ho più di due gruppi uso la select -->
              <div class="col-lg-6 col-md-12" v-if="getUserObj.groups.legth > 3">
                <el-select class="select-primary" size="large" placeholder="Single Select" v-model="currentGroup"
                  value-key="name">
                  <el-option v-for="option in getUserObj.groups" class="select-primary" :value="option"
                    :label="option.name" :key="option._id"> </el-option>
                </el-select>
              </div>
              <!-- Altrimenti i bottoni -->
              <div class="col-lg-6 col-md-12" v-else>
                <base-button v-for="group in getUserObj.groups" :key="group._id" class="switch-button mx-1" type="info"
                  size="sm" round @click="currentGroup = group" :class="{ active: currentGroup._id === group._id }">{{
                    group.name }}</base-button>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm12">
            <div class="row">
              <label class="col-lg-5 col-md-12 col-form-label">{{ $t("dashboard.view") }}:</label>
              <div class="col-lg-7 col-md-12" v-if="getSelectedGroupObj">
                <base-button class="switch-button" type="info" size="sm" round @click="currentViewType = 'map'"
                  :class="{ active: currentViewType === 'map' }">Map</base-button>
                <base-button class="switch-button mx-1" type="info" size="sm" round @click="currentViewType = 'img'"
                  :disabled="!getSelectedGroupObj.media"
                  :class="{ active: currentViewType === 'img' }">Img/Video</base-button>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
    <!-- Maps -->
    <div class="col-lg-8 col-md-12" align="left">
      <card type="plain" class="flat-card" :header-classes="{ 'text-right': isRTL }">
        <MapsGbs v-if="currentViewType === 'map' && getSelectedGroupObj && getSelectedGroupObj.sensors"
          :enabled="currentViewType === 'map'" :devices="getSelectedGroupObj ? getSelectedGroupObj.sensors || [] : []"
          :selectedDeviceID="getSelectedDeviceObj ? getSelectedDeviceObj._id : null"
          :editingDeviceID="positioning.editingDeviceID" @select-devices="setSelectedDevicesID"
          @update-sensor-position="updateSensorPosition" />
        <ImageGbs v-if="currentViewType === 'img'" :videoFormat="mediaType === 'video'" :mediaUrl="mediaUrl"
          :devices="getSelectedGroupObj ? getSelectedGroupObj.sensors || [] : []"
          :selectedDeviceID="getSelectedDeviceObj ? getSelectedDeviceObj._id : null"
          :editingDeviceID="positioning.editingDeviceID" @select-devices="setSelectedDevicesID"
          @update-sensor-position="updateSensorPosition" />
      </card>
    </div>

    <div class="col-lg-4 col-md-12">
      <div class="row">
        <!-- Device details -->
        <div class="col-lg-12">
          <card class="card" :header-classes="{ 'text-right': isRTL }">
            <h4 slot="header" class="card-title">{{ $t("dashboard.selectedDeviceDetails") }}</h4>
            <div class="table-responsive">
              <DeviceDetailsTableGbs />
            </div>
          </card>
        </div>
        <!-- Cruscotto -->
        <div class="col-lg-12" v-if="someValidLastValues">
          <dash-counter :values="lastValues" :icon="'tim-icons icon-square-pin'" />
        </div>
        <!-- Device controls -->
        <div class="col-lg-12 d-none d-sm-block">
          <card class="card" :header-classes="{ 'text-right': isRTL }">
            <div class="row">
              <div class="col-md-6" align="center" v-if="this.positioning.editingDeviceID === null">
                <base-button type="info" class="btn-full" @click="showDeviceSettings = true">
                  <i class="tim-icons icon-settings"></i>{{ $t("deviceControls.settings") }}</base-button>
              </div>
              <div class="col-md-6" align="center" v-if="this.positioning.editingDeviceID !== null">
                <base-button type="warning" class="btn-full" @click="undoMoveDevice"> <i
                    class="tim-icons icon-square-pin"></i>Annulla</base-button>
              </div>
              <div class="col-md-6" align="center" v-if="this.positioning.editingDeviceID === null">
                <base-button type="info" class="btn-full" @click="moveSelectedDevice"> <i
                    class="tim-icons icon-square-pin"></i>{{ $t("deviceControls.relocate") }}</base-button>
              </div>
              <div class="col-md-6" align="center" v-if="this.positioning.editingDeviceID !== null">
                <base-button type="success" class="btn-full" @click="saveSensorPosition"> <i
                    class="tim-icons icon-square-pin"></i>Salva</base-button>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

    <!-- Date picker Desktop -->
    <div class="col-lg-12 col-md-12 d-none d-md-block" v-if="!getSelectedDeviceObj.staticType">
      <card>
        <h4 slot="header" class="card-title">{{ $t("datePicker.header") }}</h4>
        <base-input>
          <el-date-picker type="daterange" :start-placeholder="$t('datePicker.startDate')"
            :end-placeholder="$t('datePicker.endDate')" placeholder="Date Picker" v-model="datePicker"
            :picker-options="datePickerOptions" size="small">
          </el-date-picker>
        </base-input>
      </card>
    </div>

    <!-- Date picker Mobile -->
    <div class="col-lg-12 col-md-12 d-sm-none" v-if="!getSelectedDeviceObj.staticType">
      <card>
        <div class="row">
          <div class="col-6">
            <h4 slot="header" class="card-title">Data inizio</h4>
            <base-input>
              <el-date-picker :clearable="false" type="date" placeholder="Date Picker" v-model="datePickerSingle1">
              </el-date-picker>
            </base-input>
          </div>
          <div class="col-6">
            <h4 slot="header" class="card-title">Data fine</h4>
            <base-input>
              <el-date-picker :clearable="false" type="date" placeholder="Date Picker" v-model="datePickerSingle2">
              </el-date-picker>
            </base-input>
          </div>
        </div>
      </card>
    </div>

    <!-- AWR Chart -->
    <div class="col-12" v-if="aggregatedStats.AWR.values.length > 0">
      <DataChartGBS v-if="aggregatedStats.AWR.values && getSelectedDeviceObj" :chartTitle="'AWR'"
        :values="[aggregatedStats.AWR.values]" :dates="aggregatedStats.dates" :mesUnit="'[mG]'" :categories="[]"
        :deviceID="getSelectedDeviceObj.device" :columnName="aggregatedStats.AWR.name" :alerts="aggregatedAlerts.awr"
        :colors="['#FFFF00']" :showChartAnimation="showChartAnimation" />
    </div>

    <!-- AWR AVG hart -->
    <div class="col-12" v-if="aggregatedStats.AVG.values.length > 0 && aggregatedMeasures.acceleration.values">
      <DataChartGBS v-if="aggregatedStats.AVG.values && getSelectedDeviceObj" :chartTitle="'AVG'"
        :values="[aggregatedStats.AVG.values]"
        :filledValues="[aggregatedStats.margin.values[0], aggregatedStats.margin.values[1]]"
        :dates="aggregatedStats.dates" :mesUnit="'[G]'" :categories="[]" :deviceID="getSelectedDeviceObj.device"
        :columnName="aggregatedStats.AVG.name" :colors="['#FF0000', '#800080']"
        :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Acc hart -->
    <!--  <div class="col-12" v-if="aggregatedMeasures.acceleration.values && aggregatedMeasures.acceleration.values[0] && aggregatedMeasures.acceleration.values[0].length > 0">
      <DataChartGBSPlotly
        v-if="aggregatedMeasures.acceleration.values && getSelectedDeviceObj"
        :chartTitle="'Acceleration'"
        :values="[aggregatedMeasures.acceleration.values[0], aggregatedMeasures.acceleration.values[1], aggregatedMeasures.acceleration.values[2]]"
        :dates="aggregatedMeasures.dates"
        :mesUnit="'[G]'"
        :categories="[]"
        :deviceID="getSelectedDeviceObj.device"
        :columnName="aggregatedMeasures.acceleration.name"
        :colors="['red', 'green', 'blue']"
        :showChartAnimation="showChartAnimation"
      />
    </div> -->

    <!-- Velocity Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('velocity')">
      <DataChartGBS
        v-if="aggregatedAlerts.velocity && aggregatedMeasures.velocity.values && aggregatedMeasures.velocity.values.length > 0 && getSelectedDeviceObj"
        :chartTitle="getTitle('velocity') || this.$t('dataTypes.velocity')" :values="[aggregatedMeasures.velocity.values]"
        :dates="aggregatedMeasures.dates" :mesUnit="getLabelOfType('velocity')" :categories="[]"
        :deviceID="getSelectedDeviceObj.device" :columnName="aggregatedMeasures.velocity.name"
        :alerts="aggregatedAlerts.velocity" :colors="['#ffff00']" :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Velocity (2) Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('velocity2')">
      <DataChartGBS
        v-if="aggregatedAlerts.velocity2 && aggregatedMeasures.velocity2.values && aggregatedMeasures.velocity2.values.length > 0 && getSelectedDeviceObj"
        :chartTitle="getTitle('velocity2') || this.$t('dataTypes.velocity2')"
        :values="[aggregatedMeasures.velocity2.values]" :dates="aggregatedMeasures.dates"
        :mesUnit="getLabelOfType('velocity2')" :categories="[]" :deviceID="getSelectedDeviceObj.device"
        :columnName="aggregatedMeasures.velocity2.name" :alerts="aggregatedAlerts.velocity2" :colors="['#ffff00']"
        :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Flowrate Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('flowrate')">
      <DataChartGBS v-if="aggregatedMeasures.flowrate.values && getSelectedDeviceObj && aggregatedAlerts.flowrate"
        :chartTitle="getTitle('flowrate') || this.$t('dataTypes.flowrate')" :values="[flowrateValues]"
        :dates="flowRateAggregatedDates" :mesUnit="getLabelOfType('flowrate')" :categories="[]"
        :deviceID="getSelectedDeviceObj.device" :columnName="aggregatedMeasures.flowrate.name"
        :alerts="aggregatedAlerts.flowrate" :colors="['#FF0000']" :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Flowrate (2) Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('flowrate2')">
      <DataChartGBS v-if="aggregatedMeasures.flowrate2.values && getSelectedDeviceObj && aggregatedAlerts.flowrate2"
        :chartTitle="getTitle('flowrate2') || this.$t('dataTypes.flowrate2')" :values="[flowrate2Values]"
        :dates="flowRate2AggregatedDates" :mesUnit="getLabelOfType('flowrate2')" :categories="[]"
        :deviceID="getSelectedDeviceObj.device" :columnName="aggregatedMeasures.flowrate2.name"
        :alerts="aggregatedAlerts.flowrate2" :colors="['#FF0000']" :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Power Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('power')">
      <DataChartGBS v-if="aggregatedAlerts.power && aggregatedMeasures.power.values && getSelectedDeviceObj"
        :chartTitle="getTitle('power') || this.$t('dataTypes.power')" :values="[aggregatedMeasures.power.values]"
        :dates="aggregatedMeasures.dates" :mesUnit="getLabelOfType('power')" :categories="[]"
        :deviceID="getSelectedDeviceObj.device" :columnName="aggregatedMeasures.power.name"
        :alerts="aggregatedAlerts.power" :colors="['#C934EB']" :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Power (2) Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('power2')">
      <DataChartGBS v-if="aggregatedAlerts.power2 && aggregatedMeasures.power2.values && getSelectedDeviceObj"
        :chartTitle="getTitle('power2') || this.$t('dataTypes.power2')" :values="[aggregatedMeasures.power2.values]"
        :dates="aggregatedMeasures.dates" :mesUnit="getLabelOfType('power2')" :categories="[]"
        :deviceID="getSelectedDeviceObj.device" :columnName="aggregatedMeasures.power2.name"
        :alerts="aggregatedAlerts.power2" :colors="['#C934EB']" :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Distance Chart -->
    <div class="col-12"
      v-if="getAvailableDataTypes.includes('distance') && aggregatedAlerts.distance && aggregatedMeasures.distance && aggregatedMeasures.distance.values.length > 0 && getSelectedDeviceObj">
      <DataChartGBS :chartTitle="getTitle('distance') || this.$t('dataTypes.height')"
        :values="[aggregatedMeasures.distance ? aggregatedMeasures.distance.values : []]"
        :dates="aggregatedMeasures.dates" :mesUnit="getLabelOfType('distance')" :categories="[]"
        :deviceID="getSelectedDeviceObj.device" :columnName="(aggregatedMeasures.distance || {}).name"
        :alerts="aggregatedAlerts.distance" :colors="['#FFA500']" :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Temperature Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('temperature')">
      <DataChartGBS
        v-if="aggregatedAlerts.temperature && aggregatedMeasures.temperature.values && aggregatedMeasures.temperature.values.length > 0 && getSelectedDeviceObj"
        :chartTitle="getTitle('temperature') || this.$t('dataTypes.temperature')"
        :values="[aggregatedMeasures.temperature.values]" :dates="aggregatedMeasures.dates"
        :mesUnit="getLabelOfType('temperature')" :categories="[]" :deviceID="getSelectedDeviceObj.device"
        :columnName="aggregatedMeasures.temperature.name" :alerts="aggregatedAlerts.temperature" :colors="['#049800']"
        :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Temperature (2) Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('temperature2')">
      <DataChartGBS
        v-if="aggregatedAlerts.temperature2 && aggregatedMeasures.temperature2.values && aggregatedMeasures.temperature2.values.length > 0 && getSelectedDeviceObj"
        :chartTitle="getTitle('temperature2') || this.$t('dataTypes.temperature2')"
        :values="[aggregatedMeasures.temperature2.values]" :dates="aggregatedMeasures.dates"
        :mesUnit="getLabelOfType('temperature2')" :categories="[]" :deviceID="getSelectedDeviceObj.device"
        :columnName="aggregatedMeasures.temperature2.name" :alerts="aggregatedAlerts.temperature2" :colors="['#049800']"
        :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Rain Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('rain')">
      <DataChartGBS v-if="aggregatedMeasures.rain.values && getSelectedDeviceObj && aggregatedAlerts.rain"
        :chartTitle="getTitle('rain') || this.$t('dataTypes.hourlyRain')" :values="[rainValues]"
        :dates="rainAggregatedDates" :mesUnit="getLabelOfType('rain')" :categories="[]"
        :deviceID="getSelectedDeviceObj.device" :columnName="aggregatedMeasures.rain.name" :alerts="aggregatedAlerts.rain"
        :colors="['#FF0000']" :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Rain (2) Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('rain2')">
      <DataChartGBS v-if="aggregatedMeasures.rain2.values && getSelectedDeviceObj && aggregatedAlerts.rain2"
        :chartTitle="getTitle('rain2') || this.$t('dataTypes.hourlyRain2')" :values="[rain2Values]"
        :dates="rain2AggregatedDates" :mesUnit="getLabelOfType('rain2')" :categories="[]"
        :deviceID="getSelectedDeviceObj.device" :columnName="aggregatedMeasures.rain2.name"
        :alerts="aggregatedAlerts.rain2" :colors="['#FF0000']" :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Humidity Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('humidity')">
      <DataChartGBS
        v-if="aggregatedAlerts.humidity && aggregatedMeasures.humidity.values && aggregatedMeasures.humidity.values.length > 0 && getSelectedDeviceObj"
        :chartTitle="getTitle('humidity') || this.$t('dataTypes.humidity')" :values="[aggregatedMeasures.humidity.values]"
        :dates="aggregatedMeasures.dates" :mesUnit="getLabelOfType('humidity')" :categories="[]"
        :deviceID="getSelectedDeviceObj.device" :columnName="aggregatedMeasures.humidity.name"
        :alerts="aggregatedAlerts.humidity" :colors="['#ffff00']" :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Humidity (2) Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('humidity2')">
      <DataChartGBS
        v-if="aggregatedAlerts.humidity2 && aggregatedMeasures.humidity2.values && aggregatedMeasures.humidity2.values.length > 0 && getSelectedDeviceObj"
        :chartTitle="getTitle('humidity2') || this.$t('dataTypes.humidity2')"
        :values="[aggregatedMeasures.humidity2.values]" :dates="aggregatedMeasures.dates"
        :mesUnit="getLabelOfType('humidity2')" :categories="[]" :deviceID="getSelectedDeviceObj.device"
        :columnName="aggregatedMeasures.humidity2.name" :alerts="aggregatedAlerts.humidity2" :colors="['#ffff00']"
        :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Pressure Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('pressure')">
      <DataChartGBS v-if="aggregatedAlerts.pressure && aggregatedMeasures.pressure.values && getSelectedDeviceObj"
        :chartTitle="getTitle('pressure') || this.$t('dataTypes.pressure')" :values="[aggregatedMeasures.pressure.values]"
        :dates="aggregatedMeasures.dates" :mesUnit="getLabelOfType('pressure')" :categories="[]"
        :deviceID="getSelectedDeviceObj.device" :columnName="aggregatedMeasures.pressure.name"
        :alerts="aggregatedAlerts.pressure" :colors="['#800080']" :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Pressure (2) Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('pressure2')">
      <DataChartGBS v-if="aggregatedAlerts.pressure2 && aggregatedMeasures.pressure2.values && getSelectedDeviceObj"
        :chartTitle="getTitle('pressure2') || this.$t('dataTypes.pressure2')"
        :values="[aggregatedMeasures.pressure2.values]" :dates="aggregatedMeasures.dates"
        :mesUnit="getLabelOfType('pressure2')" :categories="[]" :deviceID="getSelectedDeviceObj.device"
        :columnName="aggregatedMeasures.pressure2.name" :alerts="aggregatedAlerts.pressure2" :colors="['#800080']"
        :showChartAnimation="showChartAnimation" />
    </div>

    <!-- Pulses 1 Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('pulse1')">
      <DataChartGBS v-if="aggregatedAlerts.pulse1 && aggregatedMeasures.pulse1.values && getSelectedDeviceObj"
        :chartTitle="(getTitle('pulse1') || this.$t('dataTypes.pulses')) + ' (CH. 1)'"
        :values="[aggregatedMeasures.pulse1.values]" :dates="aggregatedMeasures.dates" :mesUnit="getLabelOfType('pulse1')"
        :categories="[]" :deviceID="getSelectedDeviceObj.device" :columnName="aggregatedMeasures.pulse1.name"
        :alerts="aggregatedAlerts.pulse1" :showChartAnimation="showChartAnimation" :colors="['#800080']" />
    </div>

    <!-- Pulses 2 Chart -->
    <div class="col-12" v-if="getAvailableDataTypes.includes('pulse2')">
      <DataChartGBS v-if="aggregatedAlerts.pulse1 && aggregatedMeasures.pulse2.values && getSelectedDeviceObj"
        :chartTitle="(getTitle('pulse2') || this.$t('dataTypes.pulses')) + ' (CH. 2)'"
        :values="[aggregatedMeasures.pulse2.values]" :dates="aggregatedMeasures.dates" :mesUnit="getLabelOfType('pulse2')"
        :categories="[]" :deviceID="getSelectedDeviceObj.device" :columnName="aggregatedMeasures.pulse2.name"
        :alerts="aggregatedAlerts.pulse2" :showChartAnimation="showChartAnimation" :colors="['#800080']" />
    </div>

    <!-- Analog Chart 1-->
    <div class="col-6" v-if="getAvailableDataTypes.includes('analog1')">
      <DataChartGBS v-if="aggregatedMeasures.analog1.values && getSelectedDeviceObj"
        :chartTitle="getSelectedDeviceObj.inputs.find((inp) => inp.source === 'analog1').title || 'Analog 1'"
        :values="[aggregatedMeasures.analog1.values]" :dates="aggregatedMeasures.dates"
        :mesUnit="getLabelOfType('analog1')" :categories="[]" :deviceID="getSelectedDeviceObj.device"
        :columnName="aggregatedMeasures.analog1.name" :alerts="aggregatedAlerts.analog1"
        :showChartAnimation="showChartAnimation" :colors="['#FFFF00']" />
    </div>

    <!-- Analog Chart 2 -->
    <div class="col-6" v-if="getAvailableDataTypes.includes('analog2')">
      <DataChartGBS v-if="aggregatedMeasures.analog2.values && getSelectedDeviceObj"
        :chartTitle="getSelectedDeviceObj.inputs.find((inp) => inp.source === 'analog2').title || 'Analog 2'"
        :values="[aggregatedMeasures.analog2.values]" :dates="aggregatedMeasures.dates"
        :mesUnit="getLabelOfType('analog2')" :categories="[]" :deviceID="getSelectedDeviceObj.device"
        :columnName="aggregatedMeasures.analog2.name" :alerts="aggregatedAlerts.analog2"
        :showChartAnimation="showChartAnimation" :colors="['#FFFF00']" />
    </div>

    <!-- Analog Chart 3 -->
    <div class="col-6" v-if="getAvailableDataTypes.includes('analog3')">
      <DataChartGBS v-if="aggregatedMeasures.analog3.values && getSelectedDeviceObj"
        :chartTitle="getSelectedDeviceObj.inputs.find((inp) => inp.source === 'analog3').title || 'Analog 3'"
        :values="[aggregatedMeasures.analog3.values]" :dates="aggregatedMeasures.dates"
        :mesUnit="getLabelOfType('analog3')" :categories="[]" :deviceID="getSelectedDeviceObj.device"
        :columnName="aggregatedMeasures.analog3.name" :alerts="aggregatedAlerts.analog3"
        :showChartAnimation="showChartAnimation" :colors="['#FF0000']" />
    </div>

    <!-- Analog Chart 4  -->
    <div class="col-6" v-if="getAvailableDataTypes.includes('analog4')">
      <DataChartGBS v-if="aggregatedMeasures.analog4.values && getSelectedDeviceObj"
        :chartTitle="getSelectedDeviceObj.inputs.find((inp) => inp.source === 'analog4').title || 'Analog 4'"
        :values="[aggregatedMeasures.analog4.values]" :dates="aggregatedMeasures.dates"
        :mesUnit="getLabelOfType('analog4')" :categories="[]" :deviceID="getSelectedDeviceObj.device"
        :columnName="aggregatedMeasures.analog4.name" :alerts="aggregatedAlerts.analog4"
        :showChartAnimation="showChartAnimation" :colors="['#008000']" />
    </div>

    <!-- Contatore -->
    <div class="col-12"
      v-if="availableCounterTypes && availableCounterTypes.length > 0 && getCountersValues && getCountersValues.length > 0">
      <CountersTableGBS :countersData="getCountersValues" :sensor="getSelectedDeviceObj"
        @update-data-type="fetchNewCountersByValueType" />
    </div>

    <!-- Camera Image -->
    <div class="col-12" v-if="getSelectedDeviceObj.staticType && getSelectedDeviceObj.staticType === 'FTP-CAMERA'">
      <CameraGbs :image64="cameraImage" :cameraID="getSelectedDeviceObj.device" />
    </div>
  </div>
</template>
<script>
/* import DataChartGBS from "./DataChartGBS.vue"; */
import DataChartGBS from "./DataChartGBS.vue";
/* import DataChartGBSPlotly from "./DataChartGBSPlotly.vue"; */
import CountersTableGBS from "./CountersTableGBS.vue";
import DeviceDetailsTableGbs from "./DeviceDetailsTableGbs";
import { DatePicker, Select, Option, Button, Dialog, Switch } from "element-ui";
import { mapActions, mapGetters } from "vuex";
import { patchSensor } from "../../services/api";
import MapsGbs from "../Components/MapsGBS";
import ImageGbs from "../Components/ImageGBS";
import DeviceSettings from "src/components/DeviceSettings";
import DashCounter from "src/components/DashCounter";
import CameraGbs from "./CameraGBS.vue";
import { normalizeDateRange, isToday } from "../../helpers/dates";
import { getLabelByDataType, getTitleByDataType } from "../../helpers/device";
import { translateDataType } from "../../helpers/translation";
import { io } from "socket.io-client";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Switch.name]: Switch,
    DeviceSettings,
    DashCounter,
    CameraGbs,
    DeviceDetailsTableGbs,
    MapsGbs,
    ImageGbs,
    CountersTableGBS,
    /* DataChartGBS, */
    DataChartGBS,
    /* DataChartGBSPlotly */
  },
  props: {
    userID: String,
  },
  data() {
    return {
      selectedCounterType: null,
      cameraImage: null, //TODO: cambiare metodo
      showChartAnimation: false,
      currentViewType: "map",
      editingRightNow: false,
      socket: null,
      showDeviceSettings: false,
      counterMode: false,
      positioning: {
        editingDeviceID: null,
        lastPosition: null,
      },
      selectedGroupID: null,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getUserObj: "user/getUserObj",
      getSelectedGroupObj: "dashboard/getSelectedGroupObj",
      getTotalSensors: "dashboard/getTotalSensors",
      getSelectedDateRange: "dashboard/getSelectedDateRange",
      getSensorPackets: "dashboard/getSensorPackets",
      getCountersValues: "dashboard/getCountersValues",
      getAvailableDataTypes: "dashboard/getAvailableDataTypes",
      getSelectedDeviceObj: "dashboard/getSelectedDeviceObj",
      getStatistics: "dashboard/getStatistics",
      getLastSensorPacket: "dashboard/getLastSensorPacket",
    }),
    availableCounterTypes() {
      return this.getSelectedDeviceObj.counters.filter((cn) => cn.enabled);
    },
    currentGroup: {
      // getter
      get: function () {
        return this.getSelectedGroupObj;
      },
      // setter
      set: function (newValue) {
        this.setSelectedGroupID(newValue._id);
      },
    },
    mediaUrl() {
      if (this.getSelectedGroupObj && this.getSelectedGroupObj.media) {
        return this.getSelectedGroupObj.media.mediaUrl;
      } else {
        return null;
      }
    },
    mediaType() {
      if (this.getSelectedGroupObj && this.getSelectedGroupObj.media) {
        return this.getSelectedGroupObj.media.mediaType;
      } else {
        return null;
      }
    },
    /* Date Picker in modalità date-range */
    datePicker: {
      set: function (val) {
        this.setSelectedDateRange(normalizeDateRange(val));
      },
      get: function () {
        return this.getSelectedDateRange;
      },
    },
    /* Date Pickers in modalità date (singola) */
    datePickerSingle1: {
      set: function (val) {
        const date2 = this.getSelectedDateRange[1];
        this.setSelectedDateRange(normalizeDateRange([val, date2]));
      },
      get: function () {
        return this.getSelectedDateRange[0];
      },
    },
    datePickerSingle2: {
      set: function (val) {
        const date1 = this.getSelectedDateRange[0];
        this.setSelectedDateRange(normalizeDateRange([date1, val]));
      },
      get: function () {
        return this.getSelectedDateRange[1];
      },
    },
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    // Computed dei pacchetti raw ricevuti (NO CONTATORI)
    aggregatedMeasures() {
      if (this.getSensorPackets && this.getSensorPackets.dates) {
        return {
          dates: this.getSensorPackets.dates ? this.getSensorPackets.dates.map((dt) => new Date(dt)) : [],
          averageValues: false,
          temperature: {
            values: this.getSensorPackets.temperatures,
            name: this.$t("dataTypes.temperature"),
          },
          temperature2: {
            values: this.getSensorPackets.temperatures2,
            name: this.$t("dataTypes.temperature2"),
          },
          distance: {
            values: this.getSensorPackets.distances,
            name: this.$t("dataTypes.height"),
          },
          rain: {
            values: this.getSensorPackets.rains,
            name: "Rain",
          },
          rain2: {
            values: this.getSensorPackets.rains2,
            name: "Rain (2)",
          },
          flowrate: {
            values: this.getSensorPackets.flowrates,
            name: this.$t("dataTypes.flowrate"),
          },
          flowrate2: {
            values: this.getSensorPackets.flowrates2,
            name: this.$t("dataTypes.flowrate2"),
          },
          pulse1: {
            values: this.getSensorPackets.pulses1,
            name: this.$t("dataTypes.pulses"),
          },
          pulse2: {
            values: this.getSensorPackets.pulses2,
            name: this.$t("dataTypes.pulses"),
          },
          power: {
            values: this.getSensorPackets.powers,
            name: this.$t("dataTypes.power"),
          },
          power2: {
            values: this.getSensorPackets.powers2,
            name: this.$t("dataTypes.power2"),
          },
          humidity: {
            values: this.getSensorPackets.humidities,
            name: "Humidity",
          },
          humidity2: {
            values: this.getSensorPackets.humidities2,
            name: "Humidity (2)",
          },
          pressure: {
            values: this.getSensorPackets.pressures,
            name: "Pressure",
          },
          pressure2: {
            values: this.getSensorPackets.pressures2,
            name: "Pressure (2)",
          },
          acceleration: {
            values: [this.getSensorPackets.xAccs, this.getSensorPackets.yAccs, this.getSensorPackets.zAccs, this.getSensorPackets.modAccs],
            name: "Acceleration",
          },
          analog1: {
            values: this.getSensorPackets.analog1,
            name: "Analog 1",
          },
          analog2: {
            values: this.getSensorPackets.analog2,
            name: "Analog 2",
          },
          analog3: {
            values: this.getSensorPackets.analog3,
            name: "Analog 3",
          },
          analog4: {
            values: this.getSensorPackets.analog4,
            name: "Analog 4",
          },
          velocity: {
            values: this.getSensorPackets.velocities,
            name: "Velocity",
          },
          velocity2: {
            values: this.getSensorPackets.velocities2,
            name: "Velocity (2)",
          },
        };
      } else {
        return {};
      }
    },
    // Computed dei valori dei contatori (e relativi derivati es. portata)
    aggregatedCounters() {
      const flowrates = [];
      const flowrates_dates = [];
      const rains = [];
      const rains_dates = [];
      const flowrates2 = [];
      const flowrates2_dates = [];
      const rains2 = [];
      const rains2_dates = [];

      // Filtro i contatori rispetto ai tipi di dato
      for (const valueType of ["volume", "volume2", "rain", "rain2"]) {
        const counters = this.getCountersValues.filter(c => c.valueType === valueType);
        // Scorro i giorni dal più veccho al più nuovo
        for (let i = counters.length - 1; i >= 0; i--) {
          const rootDate = new Date(counters[i].time);
          const values = counters[i].values;

          for (let k = 0; k < values.length; k++) {
            const value = values[k];
            let prevValue = null;
            // Se sono sul primo giorno e la prima ora del range -> null, non lo plotto; altrimenti...
            if (!(i === counters.length - 1 && k === 0)) {
              // Se non è la prima ora mi prendo la precedente;
              if (k > 0) {
                prevValue = values[k - 1];
              }
              // Se invece lo è mi prendo l'ultima ora del giorno precedente
              else {
                prevValue = counters[i + 1].values[counters[i + 1].values.length - 1];
              }

              const singleDate = new Date(rootDate);
              singleDate.setHours(k);

              if (counters[i].valueType === "volume") {
                flowrates.push(Number((value - prevValue).toFixed(5)));
                flowrates_dates.push(singleDate)
              } else if (counters[i].valueType === "rain") {
                rains.push(Number((value - prevValue).toFixed(5)));
                rains_dates.push(singleDate)
              } else if (counters[i].valueType === "volume2") {
                flowrates2.push(Number((value - prevValue).toFixed(5)));
                flowrates2_dates.push(singleDate)
              } else if (counters[i].valueType === "rain2") {
                rains2.push(Number((value - prevValue).toFixed(5)));
                rains2_dates.push(singleDate)
              }
            }
          }
        }

      }
      return {
        flowrate: {
          values: flowrates,
          dates: flowrates_dates,
          name: this.$t("dataTypes.flowrate"),
        },
        rain: {
          values: rains,
          dates: rains_dates,
          name: "Rain",
        },
        flowrate2: {
          values: flowrates2,
          dates: flowrates2_dates,
          name: this.$t("dataTypes.flowrate2"),
        },
        rain2: {
          values: rains2,
          dates: rains2_dates,
          name: "Rain (2)",
        },
      };
    },
    lastValues() {
      return [
        {
          type: "power",
          value: this.getLastSensorPacket && this.getLastSensorPacket.power !== undefined ? this.getLastSensorPacket.power : null,
          mesUnit: this.getLabelOfType("power"),
        },
        {
          type: "flowrate",
          value: this.getLastSensorPacket && this.getLastSensorPacket.flowrate !== undefined ? this.getLastSensorPacket.flowrate : null,
          mesUnit: this.getLabelOfType("flowrate"),
        },
        {
          type: "power2",
          value: this.getLastSensorPacket && this.getLastSensorPacket.power2 !== undefined ? this.getLastSensorPacket.power2 : null,
          mesUnit: this.getLabelOfType("power2"),
        },
        {
          type: "flowrate2",
          value: this.getLastSensorPacket && this.getLastSensorPacket.flowrate2 !== undefined ? this.getLastSensorPacket.flowrate2 : null,
          mesUnit: this.getLabelOfType("flowrate2"),
        },
      ];
    },
    someValidLastValues() {
      return this.lastValues && this.lastValues.some((vl) => vl.value !== undefined && vl.value !== null);
    },
    aggregatedAlerts() {
      const alerts = this.getSelectedDeviceObj.userConfig.alerts;
      return {
        distance: alerts ? alerts.filter((al) => al.valueType === "distance") : null,
        flowrate: alerts ? alerts.filter((al) => al.valueType === "flowrate") : null,
        flowrate2: alerts ? alerts.filter((al) => al.valueType === "flowrate2") : null,
        rain: alerts ? alerts.filter((al) => al.valueType === "rain") : null,
        rain2: alerts ? alerts.filter((al) => al.valueType === "rain2") : null,
        pulse1: alerts ? alerts.filter((al) => al.valueType === "pulse1") : null,
        pulse2: alerts ? alerts.filter((al) => al.valueType === "pulse2") : null,
        power: alerts ? alerts.filter((al) => al.valueType === "power") : null,
        power2: alerts ? alerts.filter((al) => al.valueType === "power2") : null,
        temperature: alerts ? alerts.filter((al) => al.valueType === "temperature") : null,
        temperature2: alerts ? alerts.filter((al) => al.valueType === "temperature2") : null,
        humidity: alerts ? alerts.filter((al) => al.valueType === "humidity") : null,
        humidity2: alerts ? alerts.filter((al) => al.valueType === "humidity2") : null,
        pressure: alerts ? alerts.filter((al) => al.valueType === "pressure") : null,
        pressure2: alerts ? alerts.filter((al) => al.valueType === "pressure2") : null,
        awr: alerts ? alerts.filter((al) => al.valueType === "awr") : null,
        analog1: alerts ? alerts.filter((al) => al.valueType === "analog1") : null,
        analog2: alerts ? alerts.filter((al) => al.valueType === "analog2") : null,
        analog3: alerts ? alerts.filter((al) => al.valueType === "analog3") : null,
        analog4: alerts ? alerts.filter((al) => al.valueType === "analog4") : null,
        velocity: alerts ? alerts.filter((al) => al.valueType === "velocity") : null,
        velocity2: alerts ? alerts.filter((al) => al.valueType === "velocity2") : null,
      };
    },
    aggregatedStats() {
      let AWRs = [];
      let AVGs = [];
      let upperBound = [];
      let lowerBound = [];
      let labels = [];
      for (let i = 0; i < this.getStatistics.length; i++) {
        const stat = this.getStatistics[i];
        if (stat.AWR != null && stat.AWR != undefined) {
          AWRs.push(Number(stat.AWR.value));
          AVGs.push(Number(stat.AWR.avg / 1000));
          upperBound.push(stat.AWR.upperBound / 1000);
          lowerBound.push(stat.AWR.lowerBound / 1000);
        } else {
          AWRs.push(null);
          AVGs.push(null);
          upperBound.push(null);
          lowerBound.push(null);
        }
        labels.push(new Date(stat.endDate));
      }
      return {
        dates: labels,
        AWR: {
          values: AWRs,
          name: "AWR",
        },
        AVG: {
          values: AVGs,
          name: "AVG",
        },
        margin: {
          values: [upperBound, lowerBound],
          name: "margin",
        },
      };
    },
    /* Per la portata posso voler vedere i dati derivanti dal contatore (in attesa della media pesata) */
    flowrateValues() {
      return this.volumeCounterOn ? this.aggregatedCounters.flowrate.values : this.aggregatedMeasures.flowrate.values;
    },
    rainValues() {
      return this.aggregatedCounters.rain.values;
    },
    rainAggregatedDates() {
      return this.rainCounterOn ? this.aggregatedCounters.rain.dates : this.aggregatedMeasures.dates;
    },
    flowRateAggregatedDates() {
      return this.volumeCounterOn ? this.aggregatedCounters.flowrate.dates : this.aggregatedMeasures.dates;
    },
    rainCounterOn() {
      return this.getCountersValues && this.getCountersValues.find((cn) => cn.valueType === "rain");
    },
    energyCounterOn() {
      return this.getCountersValues && this.getCountersValues.find((cn) => cn.valueType === "energy");
    },
    volumeCounterOn() {
      return this.getCountersValues && this.getCountersValues.find((cn) => cn.valueType === "volume");
    },
    // 2nd channel
    flowrate2Values() {
      return this.volume2CounterOn ? this.aggregatedCounters.flowrate2.values : this.aggregatedMeasures.flowrate2.values;
    },
    rain2Values() {
      return this.aggregatedCounters.rain2.values;
    },
    rain2AggregatedDates() {
      return this.rain2CounterOn ? this.aggregatedCounters.rain2.dates : this.aggregatedMeasures.dates;
    },
    flowRate2AggregatedDates() {
      return this.volume2CounterOn ? this.aggregatedCounters.flowrate2.dates : this.aggregatedMeasures.dates;
    },
    rain2CounterOn() {
      return this.getCountersValues && this.getCountersValues.find((cn) => cn.valueType === "rain2");
    },
    energy2CounterOn() {
      return this.getCountersValues && this.getCountersValues.find((cn) => cn.valueType === "energy2");
    },
    volume2CounterOn() {
      return this.getCountersValues && this.getCountersValues.find((cn) => cn.valueType === "volume2");
    },
  },
  methods: {
    ...mapActions({
      fetchUserInfo: "user/fetchUserInfo",
      setSelectedGroupID: "dashboard/setSelectedGroupID",
      setSelectedDevicesID: "dashboard/setSelectedDevicesID",
      setSelectedDateRange: "dashboard/setSelectedDateRange",
      fetchSensorPackets: "dashboard/fetchSensorPackets",
      fetchStatistics: "dashboard/fetchStatistics",
    }),
    translateValueType(valueType) {
      return translateDataType(valueType);
    },
    getLabelOfType(valueType) {
      return getLabelByDataType(this.getSelectedDeviceObj, valueType);
    },
    getTitle(valueType) {
      return getTitleByDataType(this.getSelectedDeviceObj, valueType);
    },
    getDefaultViewType() {
      if (this.getSelectedGroupObj && this.getSelectedGroupObj.sensors.some((sn) => sn.loc.some((lc) => lc.type === "img"))) {
        return "img";
      } else {
        return "map";
      }
    },
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    fetchNewCountersByValueType(valueType) {
      this.selectedCounterType = valueType;
      this.fetchSensorPackets(/* { counterType: valueType } */);
    },
    handleWebSocketEvents() {
      // Per adesso refresh automatico solo un user, non in admin
      if (!this.userID) {
        this.socket = io("https://gbs-data-server.herokuapp.com", { query: { userID: this.getUserObj._id } });
        this.socket.on("connect", () => {
          console.log("[SOCKET.IO] Connected, socketid:", this.socket.id); // x8WIv7-mJelg7on_ALbx
        });
        this.socket.on("disconnect", () => {
          console.log("[SOCKET.IO] Closed");
        });
        this.socket.on("sensor-info", async (sensorInfo) => {
          // Mi assicurto che l'utente non stia modificando qualcosa
          if (!this.showDeviceSettings && !this.editingDeviceID) {
            console.log("[SOCKET.IO] Nuovi dati!", sensorInfo);
            this.showChartAnimation = false;

            await this.fetchUserInfo();
            if (isToday(new Date(this.getSelectedDateRange[1]))) {
              await Promise.all([this.fetchSensorPackets(/* { counterType: this.selectedCounterType } */), this.fetchStatistics()]);
            }
          }
        });
      }
    },
    selectGroup(groupID) {
      this.setSelectedGroupID(groupID);
    },
    notifyVue(verticalAlign, horizontalAlign, content) {
      this.$notify({
        message: "Nuovi dati ricevuti con successo da " + content + "!",
        timeout: 2500,
        icon: "tim-icons icon-sound-wave",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: "success",
      });
    },
    showEventNotifications(deviceSerial) {
      this.notifyVue("top", "right", deviceSerial);
    },
    undoMoveDevice() {
      this.positioning.editingDeviceID = null;
      this.fetchUserInfo();
    },
    moveSelectedDevice() {
      this.positioning.editingDeviceID = this.getSelectedDeviceObj._id;
    },
    updateSensorPosition(newPosition) {
      this.positioning.lastPosition = newPosition;
    },
    async saveSensorPosition() {
      this.positioning.editingDeviceID = null;
      const selectedSensorID = JSON.parse(JSON.stringify(this.getSelectedDeviceObj._id));
      const locObj = JSON.parse(JSON.stringify(this.getSelectedDeviceObj.loc));
      if (this.positioning.lastPosition != null) {
        if (this.currentViewType === "map") {
          locObj.find((loc) => loc.type === "geo").coordinates = this.positioning.lastPosition;
        } else {
          locObj.find((loc) => loc.type === "img").coordinates = this.positioning.lastPosition;
        }
        this.startLoading();
        await patchSensor(selectedSensorID, { loc: locObj }); // Aggiorno coordinate
        await this.fetchUserInfo(); // Voglio tutto
        this.stopLoading();
      }
    },
    /* async saveSensorPosition() {
      positioning.actualDevice = null;
      const selectedSensorID = getSelectedDeviceObj._id;
      const locObj = getSelectedDeviceObj.loc;
      if (positioning.lastPosition != null) {
        if (oldStyleMap.value) {
          locObj.find(loc => loc.type === "geo").coordinates = positioning.lastPosition;
        } else {
          locObj.find(loc => loc.type === "img").coordinates = positioning.lastPosition;
        }
        const loading = await loadingController.create({
          message: "Riposizione sensore..."
        });
        loading.present();
        patchSensor(selectedSensorID, { loc: locObj })
          .then(() => {
            loading.dismiss();
            fetchUserInfo();
          })
          .catch(err => window.alert("[ERROR]" + err));
      }
    } */
  },
  async mounted() {
    this.startLoading();
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = "ar";
      this.$rtl.enableRTL();
    }
    console.log("[Dashboard] Fetching user info.");
    await this.setSelectedDateRange(normalizeDateRange([new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()]));
    await this.fetchUserInfo();
    // TODO: togliere
    /* if (this.getUserObj && this.getUserObj.username === "cardella") {
      window.location.href = "https://manage.gbscloud.eu/main/dashboard";
    } */
    await this.setSelectedGroupID(this.getUserObj.groups[0]._id);
    await this.setSelectedDevicesID(this.getSelectedGroupObj ? [this.getSelectedGroupObj.sensors[0]._id] : []);
    this.selectedCounterType = (this.getSelectedDeviceObj.counters.find((inp) => inp.enabled) || {}).valueType;
    await Promise.all([this.fetchSensorPackets(/* { counterType: this.selectedCounterType } */), this.fetchStatistics()]);
    // Se ho dei contatori switcho a quel tipo di visualizzazione
    this.counterMode = this.getCountersValues.length > 0;
    this.stopLoading();
    this.handleWebSocketEvents();
    this.currentViewType = this.getDefaultViewType();
  },
  watch: {
    getSelectedDateRange: async function (newDateRange, oldDateRange) {
      if (
        newDateRange[0] &&
        newDateRange[1] &&
        oldDateRange[0] &&
        oldDateRange[1] &&
        (newDateRange[0].getTime() != oldDateRange[0].getTime() || newDateRange[1].getTime() != oldDateRange[1].getTime())
      ) {
        console.log("--------------- [WATCH] getSelectedDateRange -------------", newDateRange, oldDateRange);
        this.startLoading();
        this.selectedCounterType = (this.getSelectedDeviceObj.counters.find((inp) => inp.enabled) || {}).valueType;
        await Promise.all([this.fetchSensorPackets(/* { counterType: this.selectedCounterType } */), this.fetchStatistics()]);
        this.stopLoading();
      }
    },
    getSelectedDeviceObj: async function (newValue, oldValue) {
      // Ne avevo già selezionato uno e quello che ho selezionato adesso è diverso
      if (newValue && oldValue && oldValue._id != newValue._id) {
        console.log("[WATCH] getSelectedDeviceObj");
        this.startLoading();
        this.selectedCounterType = (this.getSelectedDeviceObj.counters.find((inp) => inp.enabled) || {}).valueType;
        await Promise.all([this.fetchSensorPackets(/* { counterType: this.selectedCounterType } */), this.fetchStatistics()]);
        this.counterMode = this.getCountersValues.length > 0;
        this.stopLoading();
      }
    },
    getSelectedGroupObj: async function (newValue, oldValue) {
      if (oldValue && newValue && newValue.sensors.length > 0 && newValue._id != oldValue._id) {
        await this.setSelectedDevicesID([newValue.sensors[0]._id]);
        this.currentViewType = this.getDefaultViewType();
      }
    },
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = "en";
      this.$rtl.disableRTL();
    }
    this.socket.disconnect();
  },
};
</script>
<style scoped>
.flat-card>.card-body {
  padding: 0;
}

.flat-card {
  height: calc(100% - 20px);
  margin-bottom: 20px;
}

.select-primary {
  width: 100%;
}
</style>
