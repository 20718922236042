// Return true if sensor is online
export function isOnline(sensor) {
  const lastTX = new Date(sensor.lastTX);
  const now = new Date();
  const MIN_30 = 30 * 60 * 1000; /* ms */
  const timeDiffMS = now.valueOf() - lastTX.valueOf();
  return lastTX && timeDiffMS < MIN_30;
}

// Return the mes unit baes on sensor objecct and valueType
export function getFullMesUnitByType(sensor, valueType) {
  if (sensor && sensor.userConfig && sensor.userConfig.mesUnits) {
    const mesUnits = sensor.userConfig.mesUnits;
    const mesUnit = mesUnits.find((ms) => ms.valueType === valueType);
    return mesUnit ? [mesUnit.unit === "m^3" ? "m³" : mesUnit.unit, mesUnit.timeBase ? "/" + mesUnit.timeBase : ""] : ["", ""];
  } else {
    return ["", ""];
  }
}

// Return the battery level in %
export function getNormalizedBatteryLvl(sensor) {
  // The scale from API is 0-5 (included)
  if (sensor.batteryLevel === null || sensor.batteryLevel === undefined) {
    return 0;
  }
  return Math.round(sensor.batteryLevel * 20);
}

// Return the quality signal level in % base on RSSI
export function getNormalizedQualitySignal(sensor) {
  // RSSI from API
  const absoluteRSSI = sensor.RSSI;
  if (absoluteRSSI === null || absoluteRSSI === undefined) return null;
  let percentageRSSI;
  if (absoluteRSSI >= -30) percentageRSSI = 100;
  else if (absoluteRSSI <= -120) percentageRSSI = 0;
  else {
    percentageRSSI = ((absoluteRSSI + 120) * 100) / 90;
  }
  return Math.round(percentageRSSI);
}

// Return the full list of available data taypes of a given sensor (native and derived)
export function getAvailableDataTypes(sensor) {
  let availableTypes = [];
  if (!sensor || !sensor.userConfig || !sensor.userConfig.currentDataTypes) return [];
  if (sensor.userConfig.currentDerivedTypes) {
    availableTypes = sensor.userConfig.currentDataTypes.concat(sensor.userConfig.currentDerivedTypes);
  } else {
    availableTypes = sensor.userConfig.currentDataTypes;
  }
  /* Multi channel */
  if (sensor.userConfig.channels) {
    availableTypes = [...new Set(sensor.userConfig.channels.map((ch) => ch.currentDataType))];
  }
  return availableTypes;
}

export function getAvailableDataTypesV2(sensor) {
  let totalTypes = [];
  if (sensor && sensor.inputs) {
    sensor.inputs.map((input) => {
      if (input.destination) {
        // Input di 2° o 3° livello
        totalTypes.push(input.destination);
      } else {
        // Input di 1° livello
        totalTypes.push(input.source);
      }
    });
  }
  if (totalTypes.includes("x") && totalTypes.includes("y") && totalTypes.includes("z")) {
    totalTypes.push("awr");
  }
  return totalTypes;
}

// Ritorna la label da utilizzare per un dato input, null se non c'è
export function getLabelByDataType(sensor, dataType) {
  let label = null;
  if (sensor && dataType && sensor.inputs) {
    // Derivato
    let input = sensor.inputs.find((input) => input.destination === dataType);
    if (!input) {
      // Nativo
      input = sensor.inputs.find((input) => input.source === dataType);
    }
    if (input && input.label) {
      label = input.label;
    } else if (input && input.mesUnit) {
      label = input.mesUnit;
    }
  }
  return label;
}

// Ritorna la label da utilizzare per un dato input, null se non c'è
export function getMesUnitByDataType(sensor, dataType) {
  let label = null;
  if (sensor && dataType && sensor.inputs) {
    // Derivato
    let input = sensor.inputs.find((input) => input.destination === dataType);
    if (!input) {
      // Nativo
      input = sensor.inputs.find((input) => input.source === dataType);
    }
    if (input && input.mesUnit) {
      label = input.mesUnit;
    } else {
      label = getDefaultMesUnitsByDataType(dataType);
    }
  }
  return label;
}

// Ritorna il titolo del grafico da utilizzare per un dato input, null se non c'è
export function getTitleByDataType(sensor, dataType) {
  let title = null;
  if (sensor && dataType && sensor.inputs) {
    // Derivato
    let input = sensor.inputs.find((input) => input.destination === dataType);
    if (!input) {
      // Nativo
      input = sensor.inputs.find((input) => input.source === dataType);
    }
    if (input && input.title) {
      title = input.title;
    }
  }
  return title;
}

export function getMesUnitsByDataType(dataType) {
  switch (dataType) {
    case "volume":
    case "volume2":
      return ["l", "m³"];
    case "flowrate":
    case "flowrate2":
      return ["l/s", "l/min", "l/h", "m³/s", "m³/min", "m³/h"];
    case "velocity":
    case "velocity2":
      return ["m/s"];
    case "power":
    case "power2":
      return ["kW", "W"];
    case "energy":
    case "energy2":
      return ["kWh", "Wh"];
    case "distance":
      // case "distance2":
      return ["cm", "m"];
    case "rain":
    case "rain2":
      return ["mm", "m"];
    case "temperature":
    case "temperature2":
      return ["°C"];
    case "humidity":
    case "humidity2":
      return ["%"];
    case "pressure":
    case "pressure2":
      return ["hPa"];
    case "x":
    case "y":
    case "z":
      return ["mg", "g"];
    case "analog1":
    case "analog2":
    case "analog3":
    case "analog4":
      return ["ADC"];
    default:
      return [];
  }
}

export function getDefaultMesUnitsByDataType(dataType) {
  switch (dataType) {
    case "x":
    case "y":
    case "z":
      return "mg";
    case "pulse1":
    case "pulse2":
      return "N";
    case "distance":
      return "cm";
    case "volume":
    case "volume2":
      return "l";
    case "flowrate":
    case "flowrate2":
      return "l/s";
    case "velocity":
    case "velocity2":
      return "m/s";
    case "power":
    case "power2":
      return "kW";
    case "energy":
    case "energy2":
      return "kWh";
    case "analog1":
    case "analog2":
    case "analog3":
    case "analog4":
      return "ADC";
    case "rain":
    case "rain2":
      return "mm";
    case "temperature":
    case "temperature2":
      return "°C";
    case "humidity":
    case "humidity2":
      return "%";
    case "pressure":
    case "pressure2":
      return "hPa";
    default:
      return undefined;
  }
}

export const availableDestinations = [
  "volume",
  "volume2",
  "flowrate",
  "flowrate2",
  "distance",
  "distance2",
  "temperature",
  "temperature2",
  "pressure",
  "pressure2",
  "humidity",
  "humidity2",
  "rain",
  "rain2",
  "velocity",
  "velocity2",
  "power",
  "power2",
  "energy",
  "energy2",
];

export const countableSources = ["energy", "energy2", "rain", "rain2", "volume", "volume2", "pulse1", "pulse2"];

export const sensorData = [
  "distance",
  // "distance2"
  "flowrate",
  "flowrate2",
  "velocity",
  "velocity2",
  "volume",
  "volume2",
  "temperature",
  "temperature2",
  "rain",
  "rain2",
  "energy",
  "energy2",
  "humidity",
  "humidity2",
  "pressure",
  "pressure2",
  "power",
  "power2",
  "pulse1",
  "pulse1Abs",
  "pulse2",
  "pulse2Abs",
  "accMotion",
  "digital1",
  "digital2",
  "x",
  "y",
  "z",
  "analog1",
  "analog2",
  "analog3",
  "analog4",
];
