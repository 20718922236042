import XLSX from "xlsx";

function convertToXLS(objArray) {
  const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (const index in array[i]) {
      if (line != "") line += String.fromCharCode(9);
      line += array[i][index];
    }
    str += line + String.fromCharCode(13) + String.fromCharCode(10);
  }
  return str;
}

export const exportXLSFile = (headers, items, fileTitle) => {
  if (headers) {
    items.unshift(headers);
  }
  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);
  const csv = convertToXLS(jsonObject);
  const exportedFilenmae = fileTitle + ".xls" || "export.xls";
  const blob = new Blob([csv], { type: "application/vnd.ms-excel" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export function downloadXLSLX(items, fileTitle) {
  /* original data */
  const filename = fileTitle + ".xlsx";
  /* const data = [
    [1, 2, 3],
    [true, false, null, "sheetjs"],
    ["foo", "bar", new Date("2014-02-19T14:30Z"), "0.3"],
    ["baz", null, "qux"],
  ]; */
  const wsName = "Values";

  if (typeof console !== "undefined") console.log(new Date());
  const wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(items);

  /* add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, wsName);

  /* write workbook */
  if (typeof console !== "undefined") console.log(new Date());
  XLSX.writeFile(wb, filename);
  if (typeof console !== "undefined") console.log(new Date());
}

export const exportCSVFile = (csv, fileTitle) => {
  const exportedFilenmae = fileTitle + ".csv" || "export.csv";
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
