<template>
  <div class="row mt-5">
    <div class="col-12">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">{{ $t("counter.counterValues") }}</h4>
        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div>
              <!-- <label>Tipo di dato:</label> -->
              <el-select class="select-info" size="small" :placeholder="'Type'" v-model="valueType">
                <el-option v-for="option in valueTypes" class="select-info" :value="option" :label="translateValueType(option) + ' [' + getMesUnit(option) + ']'" :key="option">
                </el-option>
              </el-select>
            </div>

            <el-select class="select-primary mb-3 pagination-select" v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
            <!--  -->
          </div>
          <el-table :data="queriedData">
            <!-- Value Type -->
            <el-table-column sortable :label="this.$t('counter.date')" min-width="120" property="time" align="left">
              <template slot-scope="{ row }">
                <div>{{ formatTimeField(row.time) }}</div>
              </template>
            </el-table-column>
            <!--             <el-table-column sortable :label="this.$t('counter.device')" min-width="160" property="device" align="left">
              <template slot-scope="{ row }">
                <div>{{ row.device }}</div>
              </template>
            </el-table-column> -->
            <!--             <el-table-column sortable :label="this.$t('counter.valueType')" min-width="140" property="valueType" align="left">
              <template slot-scope="{ row }">
                <div>{{ translateValueType(row.valueType) }}</div>
              </template>
            </el-table-column> -->
            <el-table-column sortable :label="this.$t('counter.totalValue')" min-width="160" property="lastValue" align="left">
              <template slot-scope="{ row }">
                <div>{{ Number(row.lastValue).toFixed(2) }}</div>
              </template>
            </el-table-column>
            <el-table-column sortable :label="this.$t('counter.dailyValue')" min-width="190" property="dailyValue" align="left">
              <template slot-scope="{ row }">
                <div>{{ row.dailyValue ? Number(row.dailyValue).toFixed(2): " - " }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">{{ $t("table.showing") }} {{ from + 1 }} {{ $t("table.to") }} {{ to }} {{ $t("table.of") }} {{ total }} {{ $t("table.entries") }}</p>
          </div>
          <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total"> </base-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import { ddmmyyyyFormat } from "../../helpers/dates";
import { getMesUnitByDataType } from "../../helpers/device";
import { translateDataType } from "../../helpers/translation";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    sensor: Object,
    countersData: Array,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.countersData.filter((cn) => cn.valueType === this.valueType);
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0 ? this.searchedData.length : this.countersData.filter((cn) => cn.valueType === this.valueType).length;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],
      valueType: "",
      valueTypes: [],
    };
  },
  methods: {
    formatTimeField(time) {
      return ddmmyyyyFormat(time);
    },
    translateValueType(valueType) {
      return translateDataType(valueType);
    },
    getValueTypesOfCounter(counters) {
      return [...new Set(counters.map((item) => item.valueType))];
    },
    getMesUnit(valueType) {
      return getMesUnitByDataType(this.sensor, valueType);
    },
  },
  watch: {
    countersData(newValue) {
      this.valueTypes = this.getValueTypesOfCounter(newValue);
      this.valueType = this.valueTypes[0];
    },
  },
  mounted() {
    if (this.countersData && this.countersData.length > 0) {
      this.valueTypes = this.getValueTypesOfCounter(this.countersData);
      this.valueType = this.valueTypes[0];
    }
  },
};
</script>
<style scoped>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content {
  font-size: inherit !important;
}
a {
  color: white;
}
</style>
