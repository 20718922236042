export function convertInputCSVtoJSON(event) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      // Use reader.result
      const csv = reader.result;
      const lines = csv.split("\n");
      const result = [];
      const headers = lines[0].split(";");
      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentline = lines[i].split(";");
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j].replace(/\s+/g, " ").trim()] = parseFloat(currentline[j]);
        }
        result.push(obj);
      }
      resolve(result);
    };
    reader.onerror = reject;
    reader.readAsText(event.target.files[0]);
  });
}

export function convertInputCSVtoDoubleArray(event) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      // Use reader.result
      const csv = reader.result;
      const lines = csv.split("\n");
      const result = { inputValues: [], outputValues: [] };
      //const headers = lines[0].split(";");
      for (let i = 1; i < lines.length; i++) {
        //const obj = {};
        const currentline = lines[i].split(";");
        const inputValue = parseFloat(currentline[0]);
        const outputValue = parseFloat(currentline[1]);
        if (!isNaN(inputValue) && !isNaN(outputValue)) {
          result.inputValues.push(parseFloat(currentline[0]));
          result.outputValues.push(parseFloat(currentline[1]));
        }
      }
      console.log("Risolvo", result);
      resolve(result);
    };
    reader.onerror = reject;
    reader.readAsText(event.target.files[0]);
  });
}
