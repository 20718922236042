<template>
  <div class="row">
    <div class="col-12">
      <card footer-classes="text-right">
        <div class="row">
          <card card-body-classes="table-full-width">
            <!-- <h4 slot="header" class="card-title">Tabella conversione</h4> -->
            <el-table size="small" cell-class-name="pt-0 pb-0" :data="tableData" height="350" style="width: 100%">
              <el-table-column min-width="150" :label="inputDataType" property="source"></el-table-column>
              <el-table-column min-width="150" :label="outputDataType" property="destination"></el-table-column>
            </el-table>
          </card>
        </div>
        <!-- <template slot="footer">
          <div class="row">
            <div class="col-12 flex">
              <base-button size="sm" type="success" class="auto-m" @click="close">Chiudi</base-button>
            </div>
          </div>
        </template> -->
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";

export default {
  components: { [Table.name]: Table, [TableColumn.name]: TableColumn },
  props: {
    inputDataType: String,
    outputDataType: String,
    inputValues: Array,
    outputValues: Array,
  },
  computed: {
    tableData() {
      return this.inputValues.map((inputValue, index) => {
        return {
          id: index,
          source: inputValue,
          destination: this.outputValues[index],
        };
      });
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
