<template>
  <div class="row">
    <div class="col-12 pb-3">
      <el-dialog :visible.sync="showAddUserModal" class="micro-dialog">
        <span slot="title"><AddUserModal @close="showAddUserModal = false" v-if="showAddUserModal" @save="addUser" /></span>
        <span></span>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>
      <card card-body-classes="table-full-width" class="h-100">
        <h4 slot="header" class="card-title">Elenco Clienti</h4>
        <base-button
          id="add-button"
          size="sm"
          type="info"
          class="button add-button"
          menu-on-right=""
          tag="div"
          title-classes="btn btn-link btn-icon"
          :class="{ 'float-left': $rtl.isRTL }"
          @click="showAddUserModal = true"
        >
          <i class="tim-icons icon-simple-add"></i>
        </base-button>
        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <!-- <el-select class="select-primary mb-3 pagination-select" v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"> </el-option>
            </el-select> -->

            <base-input>
              <el-input type="search" class="mb-3 search-input" clearable prefix-icon="el-icon-search" placeholder="Ricerca" v-model="searchQuery" aria-controls="datatables">
              </el-input>
            </base-input>
          </div>

          <el-table
            height="500"
            :data="queriedData"
            :row-style="tableRowStyle"
            @row-click="rowClicked"
            :row-class-name="'custom-row'"
            :cell-style="{ padding: '0', height: '20px' }"
          >
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label" align="left">
            </el-table-column>
            <el-table-column align="center" label="Azioni">
              <div slot-scope="props">
                <base-button @click.native="handleDelete(props.$index, props.row)" class="remove btn-link" type="danger" size="sm" icon>
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <!-- <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">Mostrati {{ from + 1 }} a {{ to }} di {{ total }} risultati</p>
          </div>
          <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total"> </base-pagination>
        </div> -->
      </card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Table, TableColumn, Select, Option, Dialog } from "element-ui";
import AddUserModal from "./AddUserModal.vue";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import config from "../../../config";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    AddUserModal,
  },
  data() {
    return {
      showAddUserModal: false,
      pagination: {
        perPage: 1000,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name", "email"],
      tableColumns: [
        {
          prop: "username",
          label: "Username",
          minWidth: 120,
        },
        {
          prop: "email",
          label: "Email",
          minWidth: 250,
        },
        {
          prop: "createdAt",
          label: "Data Creazione",
          minWidth: 120,
        },
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  computed: {
    ...mapGetters({ userList: "admin/getUserList", selectedUserID: "admin/getSelectedUserID" }),
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0 && this.searchQuery) {
        result = this.searchedData.map((el) => el.item);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
    },
  },
  methods: {
    ...mapActions({ selectUser: "admin/selectUserByID", refresh: "admin/refresh", addNewUser: "admin/addNewUser", removeUser: "admin/removeUser" }),
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    async addUser(newUser) {
      this.startLoading();
      console.log("Users.vue -> aggiungo utente", newUser);
      //await this.refresh();
      await this.addNewUser(newUser);
      this.showAddUserModal = false;
      this.stopLoading();
    },
    tableRowStyle({ row, rowIndex }) {
      if (this.selectedUserID && this.selectedUserID === row._id) {
        return { background: config.colors.info };
      }
    },
    rowClicked(row) {
      this.selectUser(row._id);
    },
    /*     handleEdit(index, row) {
      swal.fire({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-info btn-fill",
        },
      });
    }, */
    handleDelete(index, row) {
      swal
        .fire({
          title: "Sei sicuro?",
          text: `Azione irreversibile!`,
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: "Sì, cancella utente " + row.username,
          cancelButtonText: "Annulla",
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            this.startLoading();
            await this.removeUser(row._id);
            this.stopLoading();
            swal.fire({
              title: "Deleted!",
              text: `You deleted ${row._id}`,
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["username", "email"],
      threshold: 0.3,
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    userList(newValue) {
      this.tableData = (newValue || []).map((us) => {
        return { ...us, createdAt: new Date(us.createdAt).toLocaleDateString() };
      });
      if (newValue.length > 0) {
        // Se non esiste più il vecchio user selezionato, ne seleziono uno
        if (!newValue.some((usr) => usr._id === this.selectedUserID)) {
          this.selectUser(newValue[0]._id);
        }
      }
      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        keys: ["username", "email"],
        threshold: 0.3,
      });
    },
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content {
  font-size: inherit !important;
}
.custom-row {
  cursor: pointer;
}
</style>
