//Create random lat/long coordinates in a specified radius around a center point
export function randomGeo(center, radius) {
  const y0 = center[0];
  const x0 = center[1];
  const rd = radius / 111300; //about 111300 meters in one degree

  const u = Math.random();
  const v = Math.random();

  const w = rd * Math.sqrt(u);
  const t = 2 * Math.PI * v;
  const x = w * Math.cos(t);
  const y = w * Math.sin(t);

  //Adjust the x-coordinate for the shrinking of the east-west distances
  /* var xp = x / Math.cos(y0); */

  const newlat = y + y0;
  const newlon = x + x0;
  /* var newlon2 = xp + x0; */
  return [Number(newlat.toFixed(5)), Number(newlon.toFixed(5))];

  /*     return {
      'latitude': [newlat.toFixed(5), newlon.toFixed(5)],
      'longitude': newlon.toFixed(5),
      'longitude2': newlon2.toFixed(5),
      'distance': distance(center.latitude, center.longitude, newlat, newlon).toFixed(2),
      'distance2': distance(center.latitude, center.longitude, newlat, newlon2).toFixed(2),
  }; */
}
