<template>
  <div class="row">
    <div class="col-12 pb-3">
      <el-dialog :visible.sync="showSensorModal" class="pico-dialog">
        <span slot="title"><AddSensorModal @close="showSensorModal = false" v-if="showSensorModal" @save="addNewSensor" /></span>
        <span></span>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>
      <card card-body-classes="table-full-width" class="h-100">
        <h4 slot="header" class="card-title">Elenco Sensori</h4>
        <base-button
          id="add-button"
          size="sm"
          type="info"
          class="button add-button"
          menu-on-right=""
          tag="div"
          title-classes="btn btn-link btn-icon"
          :class="{ 'float-left': $rtl.isRTL }"
          @click="showSensorModal = true"
        >
          <i class="tim-icons icon-simple-add"></i>
        </base-button>
        <div>
          <!-- <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select class="select-primary mb-3 pagination-select" v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"> </el-option>
            </el-select>

            <base-input>
              <el-input type="search" class="mb-3 search-input" clearable prefix-icon="el-icon-search" placeholder="Ricerca" v-model="searchQuery" aria-controls="datatables">
              </el-input>
            </base-input>
          </div> -->

          <el-table :data="queriedData" :row-style="tableRowStyle" @row-click="rowClicked" :row-class-name="'custom-row'" :cell-style="{ padding: '0', height: '20px' }">
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label" align="left">
            </el-table-column>
            <el-table-column align="center" label="Azioni">
              <div slot-scope="props">
                <!-- <base-button @click.native="handleEdit(props.$index, props.row)" class="edit btn-link" type="danger" size="sm" icon>
                  <i class="tim-icons icon-pencil"></i>
                </base-button> -->
                <base-button @click.native="handleDelete(props.$index, props.row)" class="remove btn-link" type="danger" size="sm" icon>
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">Mostrati {{ from + 1 }} a {{ to }} di {{ total }} risultati</p>
          </div>
          <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total"> </base-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Table, TableColumn, Select, Option, Dialog } from "element-ui";
import { BasePagination } from "src/components";
import AddSensorModal from "./AddSensorModal.vue";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import config from "../../../config";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [Dialog.name]: Dialog,
    [TableColumn.name]: TableColumn,
    AddSensorModal,
  },
  data() {
    return {
      showSensorModal: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name"],
      tableColumns: [
        {
          prop: "device",
          label: "Device",
          minWidth: 120,
        },
        {
          prop: "modelName",
          label: "Modello",
          minWidth: 120,
        },
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  computed: {
    ...mapGetters({ sensors: "admin/getSensors", selectedSensorID: "admin/getSelectedSensorID" }),
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0 && this.searchQuery) {
        result = this.searchedData.map((el) => el.item);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
    },
  },
  methods: {
    ...mapActions({
      selectSensor: "admin/selectSensorByID",
      addSensorToCustomer: "admin/addAndVerifySensorToCustomer",
      removeSensorFromCustomer: "admin/removeSensorFromCustomer",
    }),
    tableRowStyle({ row, rowIndex }) {
      if (this.selectedSensorID && this.selectedSensorID === row._id) {
        return { background: config.colors.info };
      }
    },
    rowClicked(row) {
      this.selectSensor(row._id);
    },
    /* handleLike(index, row) {
      swal.fire({
        title: `You liked ${row.name}`,
        buttonsStyling: false,
        icon: "success",
        customClass: {
          confirmButton: "btn btn-success btn-fill",
        },
      });
    }, */
    handleEdit(index, row) {
      this.showSensorModal = true;
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: "Sei sicuro?",
          text: `Azione irreversibile!`,
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: "Sì, cancella!",
          cancelButtonText: "Annulla",
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            this.startLoading();
            await this.removeSensorFromCustomer(row._id);
            this.stopLoading();
            swal.fire({
              title: "Deleted!",
              text: `You deleted ${row.device}`,
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id);
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    async addNewSensor(sensor) {
      this.startLoading();
      await this.addSensorToCustomer(sensor);
      this.stopLoading();
    },
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["username", "email"],
      threshold: 0.3,
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    sensors(newValue) {
      this.tableData = (newValue || []).map((us) => {
        return { ...us, createdAt: new Date(us.createdAt).toLocaleDateString() };
      });
      if (newValue.length > 0) {
        this.selectSensor(newValue[0]._id);
      }
      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        keys: ["name"],
        threshold: 0.3,
      });
    },
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content {
  font-size: inherit !important;
}
.custom-row {
  cursor: pointer;
}
#add-button {
  position: absolute;
  top: 20px;
  right: 27px;
}
</style>
