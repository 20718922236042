<template>
  <div id="outer">
    <div id="container" class="zoomable">
      <DraggableDevice
        v-for="sensor in this.sensors"
        :key="sensor._id + refreshID"
        :sensorObj="sensor"
        :selectedDeviceID="selectedDeviceID"
        :containerWidth="containerWidth"
        :containerHeight="containerHeight"
        :draggable="editingDeviceID === sensor._id"
        @select-device="selectDevice"
        @update-sensor-position="updateSensorPosition"
      />
      <video v-if="videoFormat" id="video" autoplay loop muted @loadeddata="mediaLoaded">
        <source :src="mediaUrl" type="video/mp4" />
      </video>
      <img v-else id="plant" :src="mediaUrl" alt="Devices image" @load="mediaLoaded" />
    </div>
  </div>
</template>
<script>
import DraggableDevice from "../Components/DraggableDevice.vue";
import "balloon-css";

export default {
  name: "ImageGBS",
  components: {
    DraggableDevice,
  },
  props: {
    mediaUrl: String,
    videoFormat: Boolean,
    editingDeviceID: String,
    devices: Array,
    selectedDeviceID: String,
  },
  data() {
    return {
      refreshID: Math.random(),
      containerWidth: 0,
      containerHeight: 0,
      draggable: [],
      sensors: [],
    };
  },
  computed: {
    selectedSensor() {
      return this.devices.find((dv) => dv._id === this.selectedDeviceID);
    },
  },
  methods: {
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    mediaLoaded() {
      console.log("Media caricato.");
      //this.stopLoading();
    },
    getWidthHeightById(id) {
      const box = document.getElementById(id);
      if (!box) return [0, 0];
      const width = box.offsetWidth;
      const height = box.offsetHeight;
      console.log("getWidthHeightById " + [width, height]);
      return [width, height];
    },
    observeHeight(containerID) {
      const resizeObserver = new ResizeObserver(() => {
        const size = this.getWidthHeightById(containerID);
        this.containerWidth = size[0];
        this.containerHeight = size[1];
        console.log("New Size", this.containerWidth, this.containerHeight);
        this.refreshID = Math.random();
      });
      const outer = document.getElementById("outer");
      if (outer) resizeObserver.observe(outer);
    },
    selectDevice(sensor) {
      this.$emit("select-devices", [sensor._id]);
      //store.dispatch("dashboard/selectSensor", sensor.device);
    },
    initalization() {
      setTimeout(() => {
        this.observeHeight("container");
      }, 20);
      this.sensors = [];
      this.draggable = [];
      if (!this.selectedDeviceID && this.devices[0]) {
        //store.dispatch("dashboard/selectSensor", this.devices[0].device);
        this.$emit("select-devices", [this.devices[0].id]);
      }
      this.sensors = JSON.parse(JSON.stringify(this.devices));
    },
    updateSensorPosition(newPosition) {
      console.log("updateSensorPosition", newPosition);
      this.$emit("update-sensor-position", newPosition);
    },
  },
  mounted() {
    //this.startLoading();
    this.initalization();
    // Fallback loading
    /* setTimeout(() => {
      this.stopLoading();
    }, 3000); */
  },
  watch: {
    devices: function () {
      if (!this.editingDeviceID) {
        this.initalization();
      }
    },
  },
};
</script>
<style scoped>
#outer {
  overflow: hidden;
  max-height: 100%;
  /* width: 920px; */
  display: flex;
}
#container {
  height: inherit;
  width: inherit;
  width: fit-content;
  /* background-color: transparent; */
  overflow: hidden;
  position: relative;
  /*   height: fit-content;
  width: fit-content; */
}

#plant {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}

/* Prevents inconsistent highlighting of element while being dragged
   Copied from https://stackoverflow.com/questions/826782 */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
</style>
