<template>
  <div id="wrap" class="extended-forms">
    <!-- Device settings modal -->
    <el-dialog :visible.sync="showOutputSettingsModal" class="min-dialog">
      <span slot="title"
        ><OutputSettingsModal
          @close="showOutputSettingsModal = false"
          v-if="showOutputSettingsModal && outputObj"
          :output="outputObj"
          @save="saveOutputSettings"
          :sensors="allSensors"
      /></span>
      <span></span>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <ValidationObserver v-slot="{ invalid, dirty }" v-if="outputObj">
      <form>
        <card>
          <h4 slot="header" class="card-title">Riepilogo Output</h4>
          <div class="col-12">
            <h3 class="card-title">{{ outputObj.name }}</h3>
            <div class="row">
              <!-- Abilitato -->
              <div class="col-12 flex" v-if="outputObj.enabled !== undefined">
                <label class="mr-2">Output abilitato:</label>
                <base-switch type="info" on-text="ON" off-text="OFF" v-model="outputObj.enabled" @input="unsavedChanges = true"></base-switch>
              </div>
              <!-- Nome -->
              <div class="col-md-12">
                <ValidationProvider name="name" rules="required|min:3" v-slot="{ passed, failed, errors }">
                  <base-input
                    @input="unsavedChanges = true"
                    required
                    v-model="outputObj.name"
                    label="Nome custom"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-md-12">
                <!-- Impostazioni -->
                <div class="row">
                  <base-button :wide="true" :block="true" :simple="false" type="info" @click="openOutputModal">Impostazioni vanzate</base-button>
                </div>
              </div>
            </div>
          </div>
          <template slot="footer">
            <div class="row">
              <div class="col-6 flex">
                <base-button class="auto-m" type="success" :disabled="!unsavedChanges || invalid" @click="saveQuick">Salva </base-button>
              </div>
              <div class="col-6 flex">
                <base-button class="auto-m" type="warning" :disabled="!dirty" @click="undo">Annulla</base-button>
              </div>
            </div>
          </template>
        </card>
      </form>
    </ValidationObserver>
    <div v-else class="empty-module">
      <h3>Aggiungi/seleziona un output...</h3>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { TimeSelect, DatePicker, Select, Option, Dialog } from "element-ui";
import { BaseSwitch } from "src/components/index";
import OutputSettingsModal from "./OutputSettingsModal.vue";

export default {
  components: {
    OutputSettingsModal,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    BaseSwitch,
  },
  data() {
    return {
      showOutputSettingsModal: false,
      unsavedChanges: false,
      outputObj: null,
      enabledRadio: "2",
      disabledRadio: "2",
      images: {
        regular: null,
        avatar: null,
      },
    };
  },
  computed: {
    ...mapGetters({ output: "admin/getSelectedOutputObj", sensors: "admin/getSensors", allSensors: "admin/getAllSensors" }),
  },
  methods: {
    ...mapActions({ putExistingOutput: "admin/putExistingOutput" }),
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    openOutputModal() {
      this.showOutputSettingsModal = true;
    },
    undo() {
      this.outputObj = JSON.parse(JSON.stringify(this.sensor));
    },
    async saveQuick() {
      //this.$emit("save", this.outputObj);
      console.log("Save Quick", this.outputObj);
      this.startLoading();
      await this.putExistingOutput(this.outputObj);
      this.stopLoading();
      this.unsavedChanges = false;
    },
    async saveOutputSettings(outputObj) {
      //this.$emit("save", this.outputObj);
      console.log("Save Full", outputObj);
      this.startLoading();
      await this.putExistingOutput(outputObj);
      this.stopLoading();
      this.unsavedChanges = false;
    },
  },
  watch: {
    output(newValue) {
      if (newValue) {
        this.outputObj = JSON.parse(JSON.stringify(newValue));
      } else {
        this.outputObj = null;
      }
    },
  },
};
</script>
<style scoped>
#wrap,
.card,
form {
  height: 100%;
}
.empty-module {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
